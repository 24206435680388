import React, { useEffect, useState } from "react";
import Modal from "./createTaskModal";
import DependentModal from "./dependentModal";
import { connect } from 'react-redux';
import {  getTaskByProjectId } from './../../store/projects/action';
import { useParams } from 'react-router-dom';
import { getInitials } from "../../util/helper";
import Loader from '../../components/Common/SpinnerLoader';
import { Icon } from "@iconify/react";
import moment from 'moment'

const Task = (props) => {
    const { getTaskByProjectId } = props;
    const params = useParams();
    const { id } = params;
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDependentOpen, setIsDependentOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [selectedTask, setSelectedTask] = useState([]);
    const [selectedDependentTask, setSelectedDependentTask] = useState(null);

    const [tasks, setTasks] = useState([]);

    const handleOpenModal = (task) => {
        setModalOpen(true);
        setSelectedTask(task);
        window.$(`#create-task`).modal('show');
        window.$("#create-task")[0].style.zIndex = "1051";
        setTimeout(() => {
            window.$("#main-task-content")[0].scrollTo({ top: 0, behavior: 'smooth' })
        }, 200);

    };

    const handleCloseModal = () => {
        setModalOpen(false);
        window.$(`#create-task`).modal('hide');

    };
    const handleOpenDependentModal = (task,value) => {
        setIsDependentOpen(true);
        task && setSelectedDependentTask({task,value});
        window.$("#dependency-task-modal").modal('show')
    };

    const fetchTasks = async () => {
        async function fetch(id) {
            if (id) {
                try {
                    const tasks = await getTaskByProjectId(Number(id))
                    if (tasks) {
                        setTasks(tasks);
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 1000);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        fetch(id)
    }

    useEffect(() => {
        fetchTasks()
    }, [])
    return (

        <div>
                <div className="task-content">
                <div className="container-fluid " style={{ overflow:"auto" }}>
                        <div className="left-content">
                            <div className="row">
                                <div id="contentleft-data" >
                                    <div className="task-header pd-left-0 pd-right-0">
                                        <div className="tasklist-header">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <button disabled={true} className="i-href-task" id="addtask"><span className="iconify" data-icon="material-symbols:add"></span>
                                                        Add Task</button>

                                                    <button disabled={true} className="i-href-task"><span className="iconify" data-icon="carbon:filter"></span>
                                                        Fliter</button>
                                                    <button disabled={true} className="i-href-task"><span className="iconify" data-icon="basil:sort-outline"></span>Sort</button>

                                                </div>
                                                <div className="col-md-2">
                                                    <div className="tg-switch">
                                                        <label className="toggleSwitch nolabel" >
                                                        <input type="checkbox"  />
                                                            <a></a>
                                                            <span>
                                                                <span className="left-span"><span className="iconify" data-icon="material-symbols:format-list-bulleted"></span>
                                                                </span>
                                                                <span className="right-span"><span className="iconify" data-icon="gg:board"></span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table task-tbl">
                                                    <thead>
                                                        <tr className="list-task-tb">
                                                            <th scope="col" className="wit-7">ID</th>
                                                            <th scope="col" className="br-left wit-30">Name</th>
                                                            <th scope="col" className="br-left wit-10">Status</th>
                                                            <th scope="col" className="br-left wit-20">Assignee</th>
                                                            <th scope="col" className="br-left wit-10">Schedule</th>
                                                            <th scope="col" className="br-left wit-10">Dependencies</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="custom-accordion">
                                        <div className="accordion" id="accordionPanelstyopen">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                                                        Facility Onboarding
                                                    </button>
                                                </h2>
                                                { isLoading ? <Loader loading={isLoading} height={'20rem'} width={'80rem'} /> :
                                                        <div id="panelsStayOpen-collapseTwo" className="accordion" aria-labelledby="panelsStayOpen-headingTwo">
                                                            <div className="accordion-body ">
                                                                <div className="row pr-list">
                                                                    <div className="col-md-12">
                                                                    {tasks.length === 0 ? (
                                                                        <p className="text-center">"No Tasks to Display"</p>
                                                                    ) : (
                                                                        <table className="table task-tbl" id="tsk-list">
                                                                            <tbody>
                                                                        {tasks.map((task,index) => (
                                                                                    <tr className="list-task-tb prj-list" key={index} >
                                                                                        <td className="wit-7"><div>{task.ProcessTaskNum}</div></td>
                                                                                <td id="addtask"  onClick={() => handleOpenModal(task)} className="br-left wit-30">
                                                                                            <div className="d-flex task-dropdown">
                                                                                                <div className="flex-grow-1 overflow-hidden dep-pr-title d-flex">
                                                                                                    <Icon icon={"mdi:check-circle-outline"} className="iconify" />
                                                                                                    <div className="nm-li">{task?.Title}</div>
                                                                                                </div>
                                                                                                <div className="flex-shrink-0 text-end ped-icn">
                                                                                                    <div className="dropdown">
                                                                                                        <a className="text-muted dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                            <span className="iconify" data-icon="pepicons-pencil:dots-x"></span>
                                                                                                        </a>

                                                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                                                            <a className="dropdown-item" href="#"><span className="iconify" data-icon="ic:outline-edit"></span>
                                                                                                                Edit</a>
                                                                                                            <a className="dropdown-item" href="#"><span className="iconify" data-icon="material-symbols:content-copy-outline"></span>
                                                                                                                Duplicate</a>
                                                                                                            <a className="dropdown-item" href="#"><span className="iconify" data-icon="material-symbols:alarm-add-outline"></span>
                                                                                                                Set Reminder</a>
                                                                                                            <a className="dropdown-item rd" href="#"><span className="iconify" data-icon="ic:baseline-delete-outline"></span>
                                                                                                                Delete</a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="br-left wit-10">
                                                                                <div  className="prj-btn">
                                                                                <Icon style={{ color:task?.WMS_TaskStatus?.HEX }}  icon={"octicon:dot-fill-16"} className="iconify" />
                                                                                                {task?.WMS_TaskStatus?.Name}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="br-left wit-20 dt-data">
                                                                                            <div className="flex-data">
                                                                                                <div className="avatar-xs">
                                                                                                    <div className="avatar-title bg-soft rounded-circle">
                                                                                                        {getInitials(task?.AspNetUsers?.UserName)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span>{task?.AspNetUsers?.UserName}</span>
                                                                                            </div></td>
                                                                                        <td className="br-left wit-10"><div>{task?.StartDate ? moment(task?.StartDate).format('MMM DD') : moment(task?.ExpectedStartDate).format('MMM DD')} - {task?.EndDate ? moment(task?.EndDate).format('MMM DD') : moment(task?.ExpectedEndDate).format('MMM DD')}</div></td>
                                                                                <td onClick={() => task.WMS_ProjectDependentTasks && task.WMS_ProjectDependentTasks.length > 0 && handleOpenDependentModal(task.WMS_ProjectDependentTasks,task)} className="br-left  wit-10 " >
                                                                                            <div className="exclm-yel">
                                                                                                {task.WMS_ProjectDependentTasks && task.WMS_ProjectDependentTasks.length > 0 ? (
                                                                                                    <>

                                                                                         <Icon style={{ position: "relative" }}  icon={"streamline:interface-alert-warning-circle-warning-alert-frame-exclamation-caution-circle"} className="iconify" />

                                                                                                        <span style={{ position: "absolute", color: "#000", marginLeft: "5px" }}>
                                                                                                            <span>+{task.WMS_ProjectDependentTasks?.length}</span>
                                                                                                        </span>

                                                                                                    </>
                                                                                                ) : (
                                                                                                    null
                                                                                                )}
                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    )}
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
   

            <Modal taskLoader={setIsLoading} tasks={tasks} setTasks={setTasks} selectedTask={selectedTask} onClose={handleCloseModal} setSelectedTask={setSelectedTask} />
            <DependentModal selectedTask={selectedDependentTask} onClose={handleCloseModal} />
        </div>

    )
}
const mapStateToProps = state => ({
    project: state && state.projects.project,
});
const mapDispatchToProps = { getTaskByProjectId };

export default connect(mapStateToProps, mapDispatchToProps)(Task);  