import React from 'react';
import { useNavigate } from 'react-router-dom';
import images from '../../../assets/img'

function PasswordConfirmation() {
    const navigate = useNavigate();
    const goToLogin = () => {
        navigate('/login')
    }
    return (
        <div>
            <section className="login-section">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-lg-7 col-md-6">
                            <div className="main-img-div">
                                <h3 className="log-txt">Streamline your process<br />with ease</h3>
                                <img src={images.logoImage} className="main-img img-fluid" alt='logo' />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="login-content">
                                <div className="logomain">
                                    <h2 className="log-fnt">CLH<span className="log-360">FLOW</span>
                                        <p className="tm">powered by SecondOpinionExpert</p></h2>
                                    <p>Facility workflow management</p>
                                    <div className="psd-request">
                                        <h2>Reset password confirmation</h2>
                                        <p>Your password has been reset</p>
                                        <div className="d-grid col-12 mx-auto mt-20 mb-20">
                                            <div className="btnbox">
                                                <button className="fullwidthbtn ft-size" onClick={goToLogin}>Login</button>                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PasswordConfirmation;