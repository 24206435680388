import React from "react";
import ContentLoader from "react-content-loader";

const Loader = (props) => { 
  if (props.type === "box") {
    return   <ContentLoader viewBox="0 0 1360 900" height={900} width={1360} {...props}>
    <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
    <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
    <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />
    <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
    <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
    <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />
    <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
    <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
    <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />
    <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
    <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
    <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />
    <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
    <rect x="910" y="250" rx="0" ry="0" width="200" height="18" />
    <rect x="910" y="275" rx="0" ry="0" width="120" height="20" />
    <rect x="1130" y="20" rx="8" ry="8" width="200" height="200" />
    <rect x="1130" y="250" rx="0" ry="0" width="200" height="18" />
    <rect x="1130" y="275" rx="0" ry="0" width="120" height="20" />
    <rect x="30" y="340" rx="8" ry="8" width="200" height="200" />
    <rect x="30" y="570" rx="0" ry="0" width="200" height="18" />
    <rect x="30" y="595" rx="0" ry="0" width="120" height="20" />
    <rect x="250" y="340" rx="8" ry="8" width="200" height="200" />
    <rect x="250" y="570" rx="0" ry="0" width="200" height="18" />
    <rect x="250" y="595" rx="0" ry="0" width="120" height="20" />
    <rect x="470" y="340" rx="8" ry="8" width="200" height="200" />
    <rect x="470" y="570" rx="0" ry="0" width="200" height="18" />
    <rect x="470" y="595" rx="0" ry="0" width="120" height="20" />
    <rect x="690" y="340" rx="8" ry="8" width="200" height="200" />
    <rect x="690" y="570" rx="0" ry="0" width="200" height="18" />
    <rect x="690" y="595" rx="0" ry="0" width="120" height="20" />
    <rect x="910" y="340" rx="8" ry="8" width="200" height="200" />
    <rect x="910" y="570" rx="0" ry="0" width="200" height="18" />
    <rect x="910" y="595" rx="0" ry="0" width="120" height="20" />
    <rect x="1130" y="340" rx="8" ry="8" width="200" height="200" />
    <rect x="1130" y="570" rx="0" ry="0" width="200" height="18" />
    <rect x="1130" y="595" rx="0" ry="0" width="120" height="20" />
  </ContentLoader>
  } 
  if(props.type === "timeline")
  {
    return ( <ContentLoader viewBox="0 0 500 475" height={475} width={500} {...props}>
    <circle cx="70.2" cy="73.2" r="41.3" />
    <rect x="129.9" y="29.5" width="125.5" height="17" />
    <rect x="129.9" y="64.7" width="296" height="17" />
    <rect x="129.9" y="97.8" width="253.5" height="17" />
    <rect x="129.9" y="132.3" width="212.5" height="17" />

    <circle cx="70.7" cy="243.5" r="41.3" />
    <rect x="130.4" y="199.9" width="125.5" height="17" />
    <rect x="130.4" y="235" width="296" height="17" />
    <rect x="130.4" y="268.2" width="253.5" height="17" />
    <rect x="130.4" y="302.6" width="212.5" height="17" />

    <circle cx="70.7" cy="412.7" r="41.3" />
    <rect x="130.4" y="369" width="125.5" height="17" />
    <rect x="130.4" y="404.2" width="296" height="17" />
    <rect x="130.4" y="437.3" width="253.5" height="17" /> 
  </ContentLoader>
    )
  }
  if(props.type === "header")
  {
    return  <ContentLoader viewBox="120 0 462 80" height={37} width={300} {...props}>
    <rect x="90" y="16" rx="5" ry="5" width="321" height="15" />
    <rect x="129" y="39" rx="5" ry="5" width="220" height="9" /> 
  </ContentLoader>
  }
}

export default Loader;