import React from 'react';
import FullCalendarView from '../../components/Common/FullCalendar';
import { getAllTaskBetweenDates } from '../../store/projects/action';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';

function TaskCalendar(props) {
  const { getAllTaskBetweenDates, id } = props
  const [task, setTask] = useState([])
  const [date, setDate] = useState(new Date());
  const [TaskArray, setTaskArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const value = { ProjectId: id }
  
  const [reload, setReload] = useState();
  const reloadFunction = (res) => {
    setIsLoading(true);
    setReload(res)
  }

  useEffect(() => {
      async function fetch(id) {
        if (id) {
          try {
            const response = await getAllTaskBetweenDates(date, value)
            setTaskArray(response)
            if (response) {
              let displayResponse = response?.map((item) => {
              let ProcessTaskNum = item?.ProcessTaskNum.split("/")[2];
                return {
                  index:ProcessTaskNum || '',
                  id: item?.ProjectTaskId,
                  title: item?.Title,
                  start: item?.TaskStartDate,
                  end: item?.TaskEndDate,
                  ProjectId: item?.ProjectId,
                  AssigneeName: item?.UserName
                }
              })
              setTask(displayResponse);
               }
          } catch (error) {
            console.log(error);
          }
        }
      }
      fetch(id);
  }, [date, reload])

  const handleMonthChange = (date) => {
    setDate(date) 
  };


  return (
   <div className='container-fluid'>
    <div className='fullcalendar-container container'>
      <FullCalendarView reloadFunction={reloadFunction} isLoading={isLoading} setTask={setTask} setIsLoading={setIsLoading} task={task} handleMonthChange={handleMonthChange} TaskArray={TaskArray} />
    </div>
    
   </div>
  )
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = { getAllTaskBetweenDates };
export default connect(mapStateToProps, mapDispatchToProps)(TaskCalendar);

