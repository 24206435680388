import { Icon } from '@iconify/react'
import React from 'react'


const DependentModal = ({selectedTask}) => {
  return (
    <div className="modal fade" id="dependency-task-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered	">
      <div className="modal-content">
        <div className="modal-header">
            
          <h3 className="dependency-title" id="staticBackdropLabel">Task dependencies</h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <div className="dep-tsk-header">
                <div className="d-flex">
                    <div className="dep-header yel-btn">
                    <Icon icon={"octicon:dot-fill-16"} className="iconify" />  {selectedTask&&selectedTask?.value?.ProcessTaskNum}
                    </div>
                    <div className="dep-title">
                       {selectedTask&&selectedTask?.value?.Title}
                    </div>
                </div>
            </div>
            <div className="dep-tsk-list">
                <div className="d-flex">
                    <div className="dep-status yel-btn">
                    <Icon style={{marginRight:"6px"}} icon={"streamline:interface-alert-warning-circle-warning-alert-frame-exclamation-caution-circle"} className="iconify" />
                        Waiting on
                    </div>
                    <div className="dep-header ble-btn">
                        <Icon icon={"octicon:dot-fill-16"} className="iconify" /> 
                        {selectedTask&&selectedTask?.task[0]?.ProcessTaskNum}                   
                    </div>
                    <div className="dep-title">
                        {selectedTask&&selectedTask?.task[0]?.Title}
                    </div>

                </div>
            </div>
            </div>
      </div>
    </div>
  </div>
  )
}

export default DependentModal