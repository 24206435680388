/* eslint-disable import/no-anonymous-default-export */
import DashboardLayout from "../layouts/Dashboard";
import PublicLayout from "../layouts/Public";
import NotFound from "../containers/NotFound";
import Login from "../containers/Account/Login";
import FacilityUserLogin from "../containers/Account/FacilityUserLogin";
import ForgetPassword from '../containers/Account/ForgotPassword/index'
import ResetPassword from "../containers/Account/ResetPassword";
import Verify from "../containers/Verify";
import DashboardMain from "../containers/Dashboard";
import ProjectList from '../containers/ProjectList'
import PasswordConfirmation from "../containers/Account/ResetPassword/passwordConfirmation";
import PasswordRequest from "../containers/Account/ResetPassword/passwordRequest";
import { RoleType } from "../util/enum";
import ProjectOverview from "../containers/ProjectOverview";
// import { RoleType } from "../util/enum"; 
import DocExclude from "../containers/DocExclude";
import TaskList from "../containers/TaskList";
import GlobalCalendar from "../containers/Calendar/globalCalendar";

// routes
export default [
  {
    path: "login",
    View: Login,
    Layout: PublicLayout,
  },
  {
    path: "auth",
    View: FacilityUserLogin,
    Layout: PublicLayout,
    isGuest: true
  },
  {
    path: "verify/:token/:reset",
    View: Verify,
    Layout: PublicLayout,
  },
  {
    path: "forgotpassword",
    View: ForgetPassword,
    Layout: PublicLayout,
  },
  {
    path: "resetpassword/:token/:reset",
    View: ResetPassword,
    Layout: PublicLayout,
  },
  {
    path: "resetpassword",
    View: ResetPassword,
    Layout: PublicLayout,
  },
  {
    path: "resetpassword/confirmation",
    View: PasswordConfirmation,
    Layout: PublicLayout,
  },
  {
    path: "resetpassword/request",
    View: PasswordRequest,
    Layout: PublicLayout,
  },
  {
    path: "/*",
    View: NotFound,
    Layout: PublicLayout,
    isProtected: false,
    userPrevillege: "all",
  },
  {
    path: "dashboard",
    View: DashboardMain,
    Layout: DashboardLayout,
    isProtected: true,
    userPrevillege: [RoleType.Admin]
  },
  {
    path: "/project",
    View: ProjectList,
    Layout: DashboardLayout,
    isProtected: true,
    userPrevillege: [RoleType.Admin],
    sectionClass: 'pr-over projects'

  },
  {
    path: "/project/:id",
    View: ProjectOverview,
    Layout: DashboardLayout,
    isProtected: true,
    userPrevillege: [RoleType.Admin],
    sectionClass: 'pr-over'
  },
  {
    path: "/calendar",
    View: GlobalCalendar,
    Layout: DashboardLayout,
    isProtected: true,
    userPrevillege: [RoleType.Admin],
    sectionClass: 'pr-over doc-custom projects'
  },
  {
    path: "/docexclude/:id",
    View: DocExclude,
    Layout: DashboardLayout,
    isProtected: true,
    userPrevillege: [RoleType.Admin],
    sectionClass: "pr-over doc-custom projects"
  },
  {
    path: "/task",
    View: TaskList,
    Layout: DashboardLayout,
    isProtected: true,
    userPrevillege: [RoleType.Admin],
    sectionClass: "pr-over doc-custom projects"
  },
];
