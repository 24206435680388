import React from "react";
import { Navigate } from "react-router-dom"; 
const ProtectedRoute = ({ userPrevillege, children }) => {
  const token = localStorage.getItem("feathers-jwt");
  // const { profile } = store.getState().auth;
  // const role = profile?.roles[0]?.id; 
  const isAuthenticated = token != null;
  if (!isAuthenticated) {
    return (
      <Navigate to="/login" />
    );
  }
  else {
  //   const userHasRequiredRole = profile && (userPrevillege === "all" || userPrevillege.includes(role)) ? true : false;
  //   if (isAuthenticated && !userHasRequiredRole) {
  //     return <Navigate to="*" />;
  //   }
  //   return children;
  // }
  // const userHasRequiredRole = profile && (userPrevillege === "all" || userPrevillege.includes(role)) ? true : false;
  if (isAuthenticated) {
    return  children;
  }
}
};
export default ProtectedRoute;
