import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllActiveOverDueTask, getTaskById } from '../../store/projects/action';
import { getInitials } from '../../util/helper';
import Modal from "../Task/createTaskModal";
import ScrollComponent from '../ScrollComponent';
import {useAutoRecalled} from '../../hooks/useAutoRecalled'

const Activities = (props) => {
  const { getAllActiveOverDueTask, getTaskById } = props
  const [loading, setLoading] = useState(false);  
  const [selectedTask, setSelectedTask] = useState([]); 
  const [pageno, setPageno] = useState(0)
  const [tab, setTab] = useState(1)
  const [data, setData] = useState([]) 
  const {autoCalled, setAutoCalled} = useAutoRecalled();
  const TaskTab = { Active: 1, Overdue: 2 }
  const [tasks, setTasks] = useState([]);

  const handleOpenModal = async (taskId, ProjectId) => {  
    const SelectedTask  = await getTaskById(taskId, ProjectId)
    setSelectedTask(SelectedTask && SelectedTask[0]);
    window.$(`#create-task`).modal('show');
    window.$("#create-task")[0].style.zIndex = "1051";
    setTimeout(() => {
      window.$("#main-task-content")[0].scrollTo({ top: 0, behavior: 'smooth' })
    }, 200); 
  };  

  const handleCloseModal = () => { 
    window.$(`#create-task`).modal('hide');
  };

  const navigateToTask = (task) => { 
     handleOpenModal(task.ProjectTaskId, task.ProjectId)
  }

  useEffect(() => {
    fetchTasks(tab)
  }, [autoCalled])

  const handleScroll = async (tab) => {
    let pagenumber = pageno + 1;
    setPageno(pagenumber);
    const res = await getAllActiveOverDueTask(10, pagenumber, tab);
    if (res.total) {
      setData(prevData => ({
        ...res,
        data: [...prevData.data, ...res.data],
      }));
    }
  };


  const fetchTasks = async (tab) => {
    setTab(tab) 
    try {
      setLoading(true)
      setPageno(0)
      const tasks = await getAllActiveOverDueTask(10, 0, tab);
      if (tasks) {
        setData(tasks);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  return <div className="col-md-6 col-sm-12">
    <div className="prj-data main-dashboard activities">
      <div className="lin-dt">
        <h2>Task </h2>
        <div className="dash-tab" style={{ marginLeft: "10px" }}>
          <ul className="nav nav-pills tab-full" id="pills-tab" role="tablist">
            {Object.keys(TaskTab).map(e => (<li className="nav-item" role="presentation" style={{ ...((TaskTab[e] === TaskTab.Overdue && { marginLeft: "10px" }) || {}) }} >
              <button
                className={`nav-link pad-2 ${tab === TaskTab[e] && 'active'}`}
                id="pills-active-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-active"
                type="button"
                role="tab"
                aria-controls="pills-active"
                aria-selected="true"
                onClick={() => { fetchTasks(TaskTab[e]) }}
              >
                {e}
              </button>
            </li>))}
          </ul>
        </div>
        <div className="filter">
          <div className="dropdown d-inline-block cst-dropdown">
            <Link to={"/task"} className="p_viewall"> <span >View all</span> </Link>
          </div>
        </div>
      </div>
      <div className="">

        <div className="tab-content" id="pills-tabContent">
          {Object.keys(TaskTab).map(e => (<div
            className={`tab-pane fade ${TaskTab[e] === tab && 'show active'}`}
            id="pills-active"
            role="tabpanel"
            aria-labelledby="pills-active-tab"
          >
            <div className="lay-out">
              <ScrollComponent data={data} tab={TaskTab[e]} getInitials={getInitials}
                handleScroll={handleScroll} loading={loading} navigateToTask={navigateToTask}
                Overdue={TaskTab[e] === TaskTab.Overdue && TaskTab.Overdue}
              />
            </div>
          </div>))}
        </div>
      </div>
    </div>
    <Modal setAutoCalled={setAutoCalled}  taskLoader={setLoading} tasks={tasks} setTasks={setTasks} selectedTask={selectedTask} setSelectedTask={setSelectedTask} onClose={handleCloseModal} />
  </div>
}

const mapStateToProps = state => ({
  projectList: state && state.projects.projects
});
const mapDispatchToProps = { getAllActiveOverDueTask, getTaskById };

export default connect(mapStateToProps, mapDispatchToProps)(Activities); 
