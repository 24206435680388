import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import images from '../../../assets/img';

function PasswordRequest() {

    return (
        <div>
            <section className="login-section">
    <div className="container-xxl">
        <div className="row">
            <div className="col-lg-7 col-md-6">
                <div className="main-img-div">
                <h3 className="log-txt">Streamline your process<br />with ease</h3>
                    <img src={images.logoImage} className="main-img img-fluid" alt='logo' />
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="login-content">
                    <div className="logomain">
                        <h2 className="log-fnt">CLH<span className="log-360">FLOW</span>
                            <p className="tm">powered by SecondOpinionExpert</p></h2>
                            <p>Facility workflow management</p>
                        
                    </div>
                    <div className="psd-request">
                            <h2>Password reset request sent </h2>
                            <p>A reset password link has been sent to the specified email address. Follow the link to set a new password.</p>
                            <Link to="/login"><Icon icon="mdi:arrow-back" style={{ fontSize: '42px' }}/>
                                </Link> 
                         </div>
                     </div>
                </div>
           </div>
       </div>
</section>
        </div>
    );
}

export default PasswordRequest;