// AppContext.js
import React, { createContext, useState, useContext } from "react";

const AutoRecallContext = createContext();

const AutoRecallProvider = (WrappedComponent) => {
  const WithAppContext = (props) => {
    const [autoCalled, setAutoCalled] = useState();

    return (
      <AutoRecallContext.Provider value={{ autoCalled, setAutoCalled }}>
        <WrappedComponent {...props} />
      </AutoRecallContext.Provider>
    );
  };

  return WithAppContext;
};

const useAutoRecalled = () => {
  const context = useContext(AutoRecallContext);
  if (!context) {
    throw new Error("useAutoRecalled must be used within an AppProvider");
  }
  return context;
};

export { useAutoRecalled, AutoRecallProvider };
