import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteContainer from "./RouteContainer";
import ErrorBar from "../components/Common/Error";
import routes from "./routes";
import ProtectedRoute from "../util/protectedRoute";

const Router = () => { 
  return (
    <RouteContainer> 
      <Routes>
        {routes &&
          routes.map(({ path, View, Layout, isProtected, userPrevillege, skipLayout=false , sectionClass="", isGuest=false}) =>
            isProtected ? (
              <Route path={path} key={path} element={<Layout skipLayout={skipLayout} sectionClass={sectionClass}>
                <ProtectedRoute userPrevillege={userPrevillege}>
                  <View />
                </ProtectedRoute>
              </Layout>
              }
              />
            ) : (
              <Route path={path} key={path} element={<Layout isGuest={isGuest}>
                <View />
              </Layout>
              }
              />
            )
          )}
      </Routes>
      <ErrorBar />

    </RouteContainer>
  );
};

export default Router;
