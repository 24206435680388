import React, { useEffect, useState } from "react";
import { Circle } from 'rc-progress';
import { getProjectById, resendInvite, createProject, openDocument, getDocuments, getDocumentCategories, getFacilityProfileDocumentCategories, getDocumentTypes, startOnBoarding } from "../../store/projects/action";
import { connect } from 'react-redux';
import { doctypeByCategory, getDateFromDateString, getDaysBetweenDates, getFullNameText, getProjectHealth, getProjectHealthColor, getProjectOverview, getTotalProgressPercentage } from "../../util/helper";
import { FacilityUserForm, FacilityUserFormDoc, MessageType, ProjectHealth, ProjectStatus } from "../../util/enum";
import MessageBox from "../../components/Common/MessageBox";
import Modal from "../../components/Common/Modal";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Icon } from "@iconify/react";
import Loading from "../../components/Common/SkeletonLoader";
import AddProject from "../../components/AddProject";
import _ from 'lodash';
import Tooltip from "../../components/Common/Tooltip";
import moment from "moment";
import {useLocation} from 'react-router-dom';
import Task from "../../components/Task";
import Loader from "../../components/Common/SkeletonLoader";

import DocumentViewer from './../../components/Common/DocumentViewer/Index';
import StartOnBoardingModal from "../../components/Task/startOnBoardingModal";
import TaskCalendar from "../Calendar/taskCalendar";

const ProjectOverview = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { getProjectById, resendInvite, categories, getDocumentCategories, getFacilityProfileDocumentCategories, facilityDocCategories, openDocument, doctypes, createProject, getDocumentTypes,startOnBoarding, project } = props;
  const [data, setData] = useState({});
  const [entireData, setEntireData] = useState([])
  const [type, setType] = useState();
  const [docCategory, setDocCategory] = useState([]);
  const [facilityDocsCategory, setFacilityDocsCategory] = useState([]);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);
  const [facilityDocs, setFacilityDocs] = useState([]);
  const [facilityProfileDocs, setFacilityProfileDocs] = useState([]);
  const [displaydays, setDisplayDays] = useState(0)
  const [open, setOpen] = useState(false);
  const [documentURL, setDocumentURL] = useState("");
  const [docName, setDocName] = useState("");
  const [docURLs, setDocUrls] = useState([]);
  const [documentID, setDocumentID] = useState(0)
  const [resendEmail, setResendEmail] = useState('')
  const [category,setCategory]=useState('')
  const [docType,setDocType]=useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading,setIsLoading]=useState(false)
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const { id } = params;
  const [tab, setTab] = useState(1);
  const location = useLocation();


  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.tab &&
      location.state.tab > 0
    ) {
      setTab(location.state.tab);
    }
  }, [location]);



  useEffect(() => {
    getDocumentCategories();
    getFacilityProfileDocumentCategories();
    getDocumentTypes()
  }, []);

  useEffect(() => {
    async function fetch(id) {
      if (id) {
        let res = await getProjectById(id, { $isUser: true, $isPercentage: true, $isFacilityProfileDoc: true, $isDocs: true });
        if (res?.WMS_FacilityProfileDoc) {
          let sorted_WMS_FacilityProfileDoc = res?.WMS_FacilityProfileDoc.sort((a, b) => a.DocTypeId - b.DocTypeId)
          const allDocs = [...sorted_WMS_FacilityProfileDoc, ...res?.WMS_Documents];
          setDocUrls(allDocs);
        }

        res = await calculateProjectHealth(res);
        res && setEntireData(res)
        const project = getProjectOverview(res);
        res && res.WMS_Documents && setFacilityDocs(res.WMS_Documents);
        res && res.WMS_FacilityProfileDoc && setFacilityProfileDocs(res.WMS_FacilityProfileDoc);
        setData(project)
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
    fetch(id)
  }, [id])
  const calculateProjectHealth = async (project) => {
    if (project) {
      const TOTAL_TABS = 6;
      let data = {}
      const progress = project.WMS_ProjectCompletionPercentage;
      const date1 = new Date(project.CreatedAt);
      const date2 = new Date(project.EndDate);
      const timeDiffAllocated = Math.abs(date2.getTime() - date1.getTime());
      const daysRemainingAllocated = Math.ceil(
        timeDiffAllocated / (1000 * 3600 * 24)
      );
      const currentDate = new Date();
      const endDateTime = new Date(project.EndDate);
      const timeDiff = endDateTime.getTime() - currentDate.getTime();

      const daysRemaining = timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;

      const sumOfPercentage = progress && (progress.reduce(
        (acc, cur) => acc + cur.Percentage,
        0
      ) || 0);
      const totalHealth = TOTAL_TABS * 100;
      const Health = (sumOfPercentage / totalHealth) * 100;
      let totalTabsHealth = Health.toFixed(2);
      const remainingDaysPercentage = (daysRemaining || 0) / daysRemainingAllocated;
      const remainingProfilePercentage = 100 - totalTabsHealth;
      const remainingRatio = (remainingDaysPercentage / remainingProfilePercentage) * 100;
      let health;
      if (remainingRatio >= 0 && remainingRatio < 0.5) {
        health = ProjectHealth.AtRisk;
      }
      if (remainingRatio >= 0.5 && remainingRatio < 0.8) {
        health = ProjectHealth.RequiresAttention;
      }
      if (remainingRatio >= 0.8) {
        health = ProjectHealth.Ontrack
      }
      if (ProjectStatus.Completed === project.Status) {
        health = ProjectHealth.Ontrack
      } else if (isNaN(remainingRatio)) {
        health = ProjectHealth.AtRisk
      }
      data.Health = health;
      data.ID = project.ID;
      project.Health = health;
      await createProject(data)
      return project;
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (categories && facilityDocCategories) {
        const result = doctypeByCategory(categories && _.orderBy(categories, [c => c.Title.toLowerCase()], ['asc']), doctypes && doctypes);
        const facilityResult = _.orderBy(facilityDocCategories, [c => c.Title.toLowerCase()], ['asc']);
        setDocCategory(result)
        setFacilityDocsCategory(facilityResult)
      }
    }
    fetchData()
  }, [categories, facilityDocCategories, doctypes])


  const displayRemainingDate = () => {
    const daysToAdd = data?.EndDate;
    const date = new Date()
    const today = getDateFromDateString(date);
    const displaydates = getDaysBetweenDates(today, daysToAdd)
    if (entireData?.Status === ProjectStatus.Completed) {
      setDisplayDays(0)
    } else {
      if (displaydates < 10 && displaydates > 0) {
        const lowDate = "0" + displaydates
        setDisplayDays(lowDate)
      } else if (displaydates < 0) {
        setDisplayDays(0)
      } else {
        setDisplayDays(displaydates)
      }
    }
  }

  useEffect(() => {
    if (data) {
      displayRemainingDate();
    }
  }, [data]);

  const updateOverView = () => {
    async function fetch(id) {
      if (id) {
        let res = await getProjectById(id,{$isUser:true,$isPercentage : true,$isFacilityProfileDoc: true,$isDocs :true});
        const projectDetails = await calculateProjectHealth(res);
        projectDetails && setEntireData(projectDetails)
        const project = getProjectOverview(res);
        project.OnboardingDate=new Date()
        setData(project)
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
    fetch(id)
  }
  const handleStartOnBoarding = async () => {
    try {
      setIsLoading(true)
      handleModalClose();
      const result = await startOnBoarding(Number(id)); 
      let res = await getProjectById(id,{});
      setData({...data,OnboardingDate:new Date()})
      if (result) {
          setTab(2); 
          setIsLoading(false)
      } else {
        console.log('Start onboarding failed.');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false)
      setType(MessageType.Error)
      setMessage('Something went wrong !'+error)
    }
  };

  const resendConfirm = (email) => {
    window.$(`#commonModal_activation`).modal('show');
    setResendEmail(email); 
  };

  const handleResend = async () => {
    if (id) {
      const res = await resendInvite(id,resendEmail);
      if (res) {
        setType(MessageType.Success)
        setMessage('Mail sent successfully')
      }
    }
  }

  const getProjectStatusText = (status) => {
    switch (status) {
      case ProjectStatus.Open:
        return "Invitation is yet to be accepted"
      case ProjectStatus.InProgress:
        return "Invitation accepted; facility profiling is in progress"
      case ProjectStatus.Completed:
        return "Submitted facility profile for review"
      default:
        return "";
    }
  }

  const handleEdit = () => {
    window.$("#createEditProject").modal("show")
  }
  const handleOpenDoc = async (url, name, id,category,docType) => {
    
    setCategory(category)
    setDocType(docType)
      if(url){
        setDocumentID(id);
        setDocumentURL(url);
        setDocName(name)
        setOpen(true)
       
    }
  }
  const handleDowDoc = async (url, docname) => {
    let docUrl = await openDocument(url);
    if (docUrl && docUrl.signedUrl) {
      const fileName = docname || 'document';
      const link = document.createElement('a');
      link.href = docUrl.signedUrl;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(docUrl.signedUrl);
    }
  }

  const renderFacilityDocuments = (docCategory) => {
    return docCategory && Object.entries(docCategory)?.map(([category, documents], i) => {
      let allDocCategory = []?.concat(...documents);
      let uploadedDocs = allDocCategory?.filter(function (e) {
        return facilityDocs.map((c) => c.DocTypeId)?.indexOf(e.ID) > -1

      })
      return <div className="finances-data" id={`documents_${i}`} key={i}>
        <div className={`d-flex ${i === 0 && 'mt-top-30'}`} >
          <div className="flex-grow-1 overflow-hidden me-4 d-flex">
            <h4>{category}</h4><span className="ms-2 mt-1">({uploadedDocs?.length}/{allDocCategory?.length})</span>
          </div>
        </div>
        {documents?.map((d, index) => {
          return <div key={index}>
            <h5>{d[0].Title && d[0].Title}</h5>
            <div className="tab-fin">
              <table className="table fin-table">
                <thead>
                  <tr>
                    <th scope="col" className="col-85">Document name</th>
                    <th scope="col" className="col-15">Actions</th>
                  </tr>
                </thead>
                <tbody className="fin-tb-body">
                  {d?.map((doc, index) => {
                    let docs = ((facilityDocs && facilityDocs?.find(e => e?.DocTypeId === doc?.ID)))
                    return <tr key={index}>
                      <td className="wt-preline">
                        <p className="p-0 m-0">{doc?.Description}</p>
                        {docs && docs ? <span><Link href="#"><Icon icon={"mdi:paperclip"} className="iconify" />{docs.DocName}</Link></span> : <span className="cls-rd"><Icon icon={"mdi:close"} className="iconify rd" />No documents available</span>
                        }
                      </td>
                      <td>
                        {docs && docs ? <div className="action-btn d-flex " >
                          
                          <Tooltip title={"View"} placement="top" ><button onClick={() => handleOpenDoc(docs?.DocExtURL, docs?.DocName, docs?.ID,category ,doc?.Description)}><Icon icon={"ic:outline-remove-red-eye"} className="iconify" />
                          </button></Tooltip>
                          <Tooltip title={"Download"} placement="top"><button onClick={() => handleDowDoc(docs?.DocExtURL, docs?.DocName)}><Icon icon={"material-symbols:download-rounded"} className="iconify" /></button></Tooltip>
                        </div> : ''
                        }
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div>
        })}

      </div>
    })
  }

  const renderFacilityProfileDocuments = (facilityDocsCategory) => {
    return facilityDocsCategory && _.map(facilityDocsCategory, e => <>
      {_.map(e.WMS_DocTypes, (e1, i) => {
        let docs = ((facilityProfileDocs && facilityProfileDocs.find(f => f.DocTypeId === e1.ID)))
        return <div className={`d-flex fc-prf ${e.WMS_DocTypes.length - 1 === i && 'br-btm'}`} key={i}>
          <p>{e1.Description === "All" ? "All pages" : e1.Description}</p>
          <div className="action-btn">
            <Tooltip title={"View"} placement="top"><button onClick={() => handleOpenDoc(docs?.DocExtURL, docs?.DocName, docs?.ID,"FacilityProfile", e1.Description)}><Icon icon={"ic:outline-remove-red-eye"} className="iconify" />
            </button></Tooltip>
            <Tooltip title={"Download"} placement="top"><button onClick={() => handleDowDoc(docs?.DocExtURL)}><Icon icon={"material-symbols:download-rounded"} className="iconify" /></button></Tooltip>
          </div>
        </div>
      })}

    </>)
  }
  const getFacilityPercentage = (entireData) => {
    return Object.keys(FacilityUserFormDoc).map((e, index) => {
      let percentage = (entireData?.WMS_ProjectCompletionPercentage?.find(p => p?.Tab === FacilityUserFormDoc[e]) || {}).Percentage || 0
      return (<div className="row" key={index}>
        <div className="col-md-4">
          <label className="pgr">{e.charAt(0) + e.toLowerCase().slice(1)}</label>
        </div>
        <div className="col-md-6">
          <div className="progress progress-lg">
            <div className="progress-bar bg-violet" role="progressbar" style={{ width: `${percentage + "%"} ` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div className="col-md-2 pd-0">
          <span className="pgr">{percentage.toFixed(0)}%</span>
        </div>
      </div>)
    })
  }

  const navigateToDocExclude = (status) => {
    if (status) {
      localStorage.setItem('projectStatus', status);
      localStorage.setItem('projectTitle', data?.Title)
      navigate(`/docexclude/${id}`);
    }
  }
  return (
    <>
      <DocumentViewer DocTypeId={docType}  facilityDocsCategory={facilityDocsCategory} docCategory={docCategory} category={category} docType={docType} documentURL={documentURL} open={open} setOpen={setOpen} name={docName} entireDocuments={docURLs} documentID={documentID} />
      <AddProject project={data} _modalID={'createEditProject'} updateOverView={updateOverView} />
      <MessageBox message={message} setMessage={setMessage} type={type} />
      <Modal
        id={'_activation'}
        title={'Resend Activation Email'}
        content={
          `Are you sure you want to resend the activation email to ${resendEmail} ?`
        }
        submit={'Yes'}
        cancel={'No'}
        handleResend={handleResend}
      />
      <div className="container-fluid">
        <div className="overview-header">
          <div className="row bt-btm">
            <div className="col-md-1">
              <div className="ovr-data">
                <Icon icon={"material-symbols:add-home-work-outline"} className="iconify" />
              </div>
            </div>
            <div className="col-md-11">
              <div className="prj-header">
                {loading ? <Loading type="header" /> : <h4>{data?.Title}
                </h4>}
                <ul className="nav nav-pills br-btm main-nav navtab-pt tab-full" id="patients-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${tab === 1 && 'active'} pad-3`} id="pt-overview-tab" data-bs-toggle="pill" data-bs-target="#pt-overview" type="button" role="tab" aria-controls="pt-overview" aria-selected="true" onClick={() => {setTab(1);}}>Overview</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button disabled={data.Status === ProjectStatus.Completed && data.OnboardingDate == null} className={`nav-link ${tab === 2 && 'active'} pad-3`} id="pt-task-tab" data-bs-toggle="pill" data-bs-target="#pt-task" type="button" role="tab" aria-controls="pt-task" aria-selected="false" onClick={() => {setTab(2);}}>Task</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button disabled={data?.OnboardingDate === null} className={`nav-link ${tab === 3 && 'active'} pad-3`} id="pt-calendar-tab" data-bs-toggle="pill" data-bs-target="#pt-calendar" type="button" role="tab" aria-controls="pt-calendar" aria-selected="false" onClick={() => {setTab(3);}}>Calendar</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button disabled={true} className="nav-link pad-3" id="pt-workflow-tab" data-bs-toggle="pill" data-bs-target="#pt-workflow" type="button" role="tab" aria-controls="pt-workflow" aria-selected="false">Workflow</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button disabled={true} className="nav-link pad-3" id="pt-dashboard-tab" data-bs-toggle="pill" data-bs-target="#pt-dashboard" type="button" role="tab" aria-controls="pt-dashboard" aria-selected="false">Dashboard</button>
                  </li>

                </ul>
              </div>  
              {(data?.Status === ProjectStatus.Completed && data.OnboardingDate == null) ?

(isLoading ?
  <StartOnBoardingModal
  onCancel={handleModalClose}
  content="Please wait while the tasks for this project are being created"
  isLoading={isLoading}
  setIsLoading={setIsLoading}
  IsVisible={"none"}
  title=""
/>
  :
  <button onClick={handleModalOpen} type="button" className="ct-btn " id="ct-btn-id">Start Onboarding</button>
) : ""}
{isModalOpen && (
<StartOnBoardingModal
  onClick={() => { handleStartOnBoarding() }}
  onCancel={handleModalClose}
  title="Confirm"
  content="This will make the facility as approved and initiate onboarding process. Would you like to continue?"
  submit="Yes"
  cancel="No"
  submitIcon={"mdi:tick"}
  CancelIcon={"iconoir:cancel"}
/>
)}
                    
            </div>
            <div className="col-md-2">
              
            </div>
          </div>
        </div>
        {loading ? <Loading type="timeline" /> :
          tab === 1 ? <div className="overview-content">
            <div className="row ">
              <div className="col-md-8">
                <div className="prj-overview">
                  <div className="facility-details">
                    <div className="d-flex ft-mn">
                      <h4>Project details</h4>
                      <div className="ft-mn-rt">
                        <div className="dropdown d-inline-block prj-edit">
                          <Tooltip title={"Edit"} placement={"left"}>
                            <button type="button" className="btn header-item noti-icon waves-effect" onClick={handleEdit}>
                              <Icon icon={"material-symbols:edit-outline"} className="iconify" />
                            </button>
                          </Tooltip>
                        </div>

                      </div>
                      {/* <div className="ft-mn-rt">
                    <div className="dropdown d-inline-block prj-edit">
                      <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <Icon icon={"ph:gear"} className="iconify" />

                      </button>
                      <div className="dropdown-menu dropdown-menu-end bordered" >
                        <Link className="dropdown-item" href="#">Document Customization</Link>
                      </div>
                    </div>

                  </div> */}
                      <div className="ft-mn-rt">
                        <div className="dropdown d-inline-block prj-edit">
                          <Tooltip title={"Settings"} placement={"right"}>
                            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
                              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <Icon icon={"ph:gear"} className="iconify" />
                            </button>
                          </Tooltip>
                          <div className="dropdown-menu dropdown-menu-end bordered" >
                            <a href="#" onClick={() => navigateToDocExclude(data?.Status)} className="dropdown-item">Exclude documents</a>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="d-flex">
                          <Icon className="iconify" icon={"bi:people"} fontSize={25}></Icon>
                          <div className="ft-dt">
                            <label>Facility owner</label>
                            {/* <p>{data.PrimaryContact && getFullNameText({fname:data.PrimaryContact[0].FName, lname:data.PrimaryContact[0].LName,
                            mname:data.PrimaryContact[0].MName}) }</p> */}
                            <p>{(data && data?.PrimaryContact[0]?.ContactName )|| ''}</p>
                          </div>
                        </div>

                      </div>
                      <div className="col-md-4">
                        <div className="d-flex">
                          <Icon className="iconify" icon={"tabler:mail"} fontSize={25}></Icon>
                          <div className="ft-dt">
                            <label>Email</label>
                            <p>{data?.PrimaryContact[0]?.Email}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex">
                          <Icon className="iconify" icon={"carbon:ibm-process-mining"} fontSize={25}></Icon>
                          <div className="ft-dt">
                            <label>Process</label>
                            <p>{data?.workflowTitle}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex">
                          <Icon className="iconify" icon={"fluent:text-description-20-regular"} fontSize={25}></Icon>
                          <div className="ft-dt wit-97">
                            <label>Description</label>
                            <p>{data?.Description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex">
                          <Icon className="iconify" icon={"fluent-mdl2:connect-contacts"} fontSize={25}></Icon>
                          <div className="alc-dt">
                            <label>All contacts</label>
                            {data?.PrimaryContact && data?.PrimaryContact?.map((item,i) => (
                              <p key={i}>
                                {item?.ContactName} ({item?.Email}), {item.IsVerified ? <span className="clr-grn active-status">Active since {moment(item?.VerifiedAt).format('MM/DD/YYYY')} </span> :
                                  <span className="active-status">
                                    <span className="clr-red">Not activated</span>
                                    <Tooltip title={"Resend email invitation"} placement={"top"}>
                                      <button type="button" className="btn p-0 m-0 clr-blue" 
                                       onClick={() => resendConfirm(item?.Email)}
                                      >
                                        <Icon icon={"basil:refresh-outline"} className="iconify" fontSize={18} />
                                      </button>
                                    </Tooltip>
                                  </span>}
                              </p>
                            ))}
                            {data?.OtherContacts && data?.OtherContacts?.map((item,i) => (
                              <p key={i}>
                                {item?.ContactName} ({item?.Email}), {item.IsVerified ? <span className="clr-grn active-status">Active since {moment(item?.VerifiedAt).format('MM/DD/YYYY')} </span> :
                                  <span className="active-status">
                                    <span className="clr-red">Not activated</span>
                                    <Tooltip title={"Resend email invitation"} placement={"top"}>
                                      <button type="button" className="btn p-0 m-0 clr-blue" 
                                       onClick={() => resendConfirm(item?.Email)}
                                      >
                                        <Icon icon={"basil:refresh-outline"} className="iconify" fontSize={18} />
                                      </button>
                                    </Tooltip>
                                  </span>
                                }
                            </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className="list-unstyled activity-wid mb-0">

                    <li className="activity-list activity-border">
                      <div className="activity-icon avatar-md">
                        <span className="avatar-title bg-warning-subtle text-warning rounded-circle">
                          <Icon icon={"mdi:file-document-arrow-right-outline"} className="iconify" />
                        </span>
                      </div>
                      <div className="timeline-list-item">
                        <div className="d-flex">
                          <div className="flex-grow-1 overflow-hidden me-4">
                            <h5 className="mb-1 st-fnt">Sent invitation on facility profiling</h5>
                            <p className="text-truncate text-muted font-size-13">{data?.createdOn}</p>
                          </div>

                        </div>
                      </div>
                    </li>
                    <li className={`activity-list ${data?.Status === ProjectStatus.Completed ? 'activity-border' : ''}`} id="add-cls">
                      <div className="activity-icon avatar-md">
                        <span className="avatar-title  bg-primary-subtle text-primary rounded-circle" id="review-facility">
                          {data?.Status === ProjectStatus.Completed ? <Icon icon={"material-symbols:manage-search"} className="iconify" /> : <Icon icon={"mdi:clock-outline"} className="iconify" />}
                        </span>
                      </div>
                      <div className="timeline-list-item">
                        <div className="d-flex">
                          <div className="flex-grow-1 overflow-hidden me-4">
                            {data?.Status > ProjectStatus.Open ? <>
                              <h5 className="mb-1 st-fnt">{getProjectStatusText(data?.Status)}</h5>
                              <p className="text-truncate text-muted font-size-13 " id="">{data?.updatedOn}</p> 
                            </> : <>
                            <h5 className="mt-3 st-fnt">{getProjectStatusText(data?.Status)}</h5>
                            </>}
                          </div>
                        </div>
                      </div>
                    </li>
                    {data?.Status === ProjectStatus.Completed && docCategory &&
                      <li className="activity-list">
                        <div className="activity-icon avatar-md">
                          <span className="avatar-title  bg-primary-subtle text-primary rounded-circle">
                            <Icon icon={"mdi:check"} className="iconify" />
                          </span>
                        </div>
                        <div className="timeline-list-item last-item">
                          <div className="d-flex">
                            <div className="flex-grow overflow-hidden me-4 rv-fac">
                              <h5 className="mb-1 st-fnt">Facility profile</h5>
                              <p className="text-truncate text-muted font-size-13">{moment(entireData?.LastModifiedAt).format('dddd, MMMM DD, YYYY')}</p>
                            </div>
                          </div>
                          {renderFacilityProfileDocuments(facilityDocsCategory)}
                          <div className="document-view">
                            <div className="row">
                              <h5 className="fc-doc">Facility documents</h5>
                              {/* <div className="col-md-3">
                                <div className="nav-menu-data" id="header" >
                                  <nav id="navbar" className="navbar">
                                    <ul>
                                      {docCategory && _.orderBy(docCategory, [c => c.Title.toLowerCase()], ['asc']).map((d, index) => <li key={index}><Link to='#' className={`${selected === (index + 1) ? 'nav-link scrollto active cursor-pointer' : 'nav-link scrollto cursor-pointer'}`} onClick={() => handleSelect(d.ID, index)}>{d.Title}</Link></li>
                                      )}
                                    </ul>
                                  </nav>
                                </div>
                              </div> */}
                              <div className="col-md-11" >
                                {renderFacilityDocuments(docCategory)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    }
                  </ul>
                </div>
              </div>
              <div className="col-md-4">

                <div className="prj-overview-right">
                  <div className="card-view">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Project health<span>Last updated: {moment(data?.updatedOn, "dddd, MMMM Do YYYY").format("MMMM DD, YYYY")}</span></h4>
                        <div className="track-btn">
                          <div className={`${getProjectHealth(entireData?.Health).color} prj-over`}>
                            <span className="iconify" data-icon="octicon:dot-fill-16"></span>
                            {getProjectHealth(entireData?.Health).name}
                          </div>
                          <div className="prg-bar">
                            <div className="box-content  mb-2">
                              <div className="box">
                                <p>Profile progress</p>
                                <div className="circel-progress-bar">
                                  <Circle
                                    percent={getTotalProgressPercentage(entireData?.WMS_ProjectCompletionPercentage)}
                                    strokeWidth={12}
                                    strokeColor={getTotalProgressPercentage(entireData?.WMS_ProjectCompletionPercentage) > 0 ? getProjectHealthColor(entireData.Health).name === "On-track" && "#48B473" || getProjectHealthColor(entireData.Health).name === "At Risk" && "#E2435C" || getProjectHealthColor(entireData.Health).name === "Requires Attention" && "#CF8A08" : "rgb(217, 217, 217)"}
                                    className="custom-progress"
                                    trailWidth={12}
                                  />
                                  <span className="custom-progress-value">
                                    {getTotalProgressPercentage(entireData.WMS_ProjectCompletionPercentage)}%
                                  </span>
                                </div>
                              </div>
                              <div className="box-time">
                                <p>Days remaining</p>
                                <h2 style={{ color: (entireData?.Health === ProjectHealth.AtRisk) ? '#E2435C' : (entireData?.Health === ProjectHealth.RequiresAttention) ? '#CF8A08' : '#1E823A' }}>{displaydays < 0 ? 0 : displaydays}</h2>
                                <p className="duedate">Due Date: {moment(entireData.EndDate).format('LL')}</p>
                              </div>
                            </div>
                          </div>
                          {getFacilityPercentage(entireData)}
                        </div>
                      </div>
                    </div>
                    {/* <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Project status <span>Last updated: {data?.updatedOn}</span></h4>
                        <div className="track-btn">
                        <div className={getProjectStatus(entireData.Status).color}>
                            <span className="iconify" data-icon="octicon:dot-fill-16"></span>
                            {getProjectStatus(entireData.Status).name}
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Project timeline 
                        <span>Last updated: {moment(data?.updatedOn, "dddd, MMMM Do YYYY").format("MMMM DD")}</span>
                        </h4>
                        <div className="">
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <span className="iconify" data-icon="bi:people" data-width="25"></span>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h5>David De Gea joined the team</h5>
                                    <p className="text-muted">New common language will be more simple and regular than the existing.</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bx bx-package h2 text-primary"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5>Packed</h5>
                                    <p className="text-muted">To achieve this, it would be necessary to have uniform grammar.</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list active">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle bx-fade-right"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bx bx-car h2 text-primary"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5>Shipped</h5>
                                    <p className="text-muted">It will be as simple as Occidental in fact, it will be Occidental..</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bx bx-badge-check h2 text-primary"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5>Delivered</h5>
                                    <p className="text-muted">To an English person, it will seem like simplified English.</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div> : tab === 2 ? <Task /> : tab === 3 ? <TaskCalendar id={id}/> : ''
        }
      </div>
    </>)
}

const mapStateToProps = state => ({
  project: state && state.projects.project,
  categories: state.projects && state.projects.doc_category,
  facilityDocCategories: state.projects && state.projects.facility_doc_category,
  doctypes: state.projects && state.projects.doc_types,
});
const mapDispatchToProps = { getProjectById, resendInvite, createProject, getDocuments, getDocumentTypes, openDocument, getDocumentCategories, getFacilityProfileDocumentCategories,startOnBoarding };

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverview);  