import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Loader from '../../components/Common/SpinnerLoader';

const StartOnBoardingModal = ({ onClick, title, onCancel, content, submit, cancel, id = "",submitIcon,CancelIcon,isLoading }) => {
  return (
    <Modal
    show={true}
    onHide={onCancel}
    backdrop="static"
    keyboard={false}
    id={id}
    centered
    className="confirm-modal-content"
  >
    <Modal.Body >
      <div className="modal-body start-loader">
        <div className="confirm-message text-center">
          <div className="header">
            <h3>{title}</h3>   
            <p>{content}</p>
            {submit?<Button variant="primary" className="confirm-btn" onClick={onClick}>
            <Icon className="iconify" icon={submitIcon}/>
             {submit}
            </Button>:""  } 
           {cancel? <Button variant="secondary" className="cancel-btn" onClick={onCancel}>
            <Icon className="iconify" icon={CancelIcon}/>
             {cancel}
            </Button>:""}
          </div>
          
          
        </div>
        <div className="modal-loader">
          {
            isLoading?<Loader loading={isLoading} height={'0rem'} width={'1rem'} />:""
           }
           </div>
      </div>
    </Modal.Body>
  </Modal>
  );
};

export default StartOnBoardingModal;
