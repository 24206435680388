import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { doctypeByCategory, formatExcludedDocs } from "../../util/helper";
import _ from "lodash";
import image from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { MessageType, ProjectStatus } from "../../util/enum";
import MessageBox from "../../components/Common/MessageBox";
import { getDocumentCategoriesForExclude, getExcludedDocs, getFacilityProfileDocumentCategories, getDocumentTypes, excludeDocTypes, includeDocTypes } from "../../store/projects/action";
import { Link, useParams } from "react-router-dom";
import Loader from '../../components/Common/SpinnerLoader'
import { Icon } from "@iconify/react";
import { useReactToPrint } from "react-to-print";
import Tooltip from "../../components/Common/Tooltip";

const DocExclude = ({ categories, excludeddocs, includeDocTypes, doctypes, getDocumentTypes, getExcludedDocs, getDocumentCategoriesForExclude, excludeDocTypes }) => {
    const [docCategory, setDocCategory] = useState({});
    const [selected, setSelected] = useState(1);
    const params = useParams();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [includedDocuments, setIncludedDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [docsLoading, setDocsLoading] = useState(true);
    const { id } = params;
    const [projectStatus, setProjectStatus] = useState();
    const [type, setType] = useState();
    const [message, setMessage] = useState();
    const navigate = useNavigate();
    const componentRef = useRef();

    useEffect(() => {
        const status = localStorage.getItem('projectStatus');
        if (status) {
            setProjectStatus(Number(status));
        }
    }, []);

    useEffect(() => {
        getDocumentCategoriesForExclude();
        getFacilityProfileDocumentCategories();
        getDocumentTypes();
        getExcludedDocs(id);
    }, []);
    useEffect(() => {
        fetchData()
        async function fetchData() {
            if (excludeddocs) {
                const result = formatExcludedDocs(excludeddocs);
                setSelectedCheckboxes(result);
            }
        }
    }, [excludeddocs])


    useEffect(() => {
        fetchData();
        async function fetchData() {
            if (doctypes && categories) {
                setDocsLoading(false);
                const result = doctypeByCategory(categories && _.orderBy(categories, [c => c.Title.toLowerCase()], ['asc']), doctypes && doctypes)
                setDocCategory(result);
            }
        }
    }, [categories, doctypes])

    const handleSelect = (ID, index) => {
        setSelected(index + 1);
        const id = `documents_${index}`;
        const yOffset = -200;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    const handleCheckboxChange = async (event, { DocTypeId }) => {
        // const checkboxValue = +event.target.value;
        const isChecked = event.target.checked;
        const item = selectedCheckboxes.find((e) => e.DocTypeId === DocTypeId);
        let data = {}
        if (isChecked) {
            if (item && item.ID) {
                const index = selectedCheckboxes.findIndex((e) => e.ID === item.ID)
                let tempValues = [...selectedCheckboxes];
                tempValues[index] = {
                    ...tempValues[index],
                    DocTypeId,
                };
                // data = {
                //     ...values,
                //     FacilityProfileAnswers: [...behavioralQuestions, ...tempValues],
                //     AncillaryServices: Ancillaryservices,
                // };
                setSelectedCheckboxes([...tempValues]);
            } else {
                setSelectedCheckboxes((prevState) => [
                    ...prevState,
                    { DocTypeId },
                ]);
                // data = {
                //     ...values,
                //     FacilityProfileAnswers: [...behavioralQuestions, ...selectedCheckboxes, { QuestionId, OptionId }],
                //     AncillaryServices: Ancillaryservices,
                // }
            }
        }
        if (!isChecked) {
            if (item && item.ID) {
                let filter = selectedCheckboxes.filter((e) => !(e.DocTypeId === item.DocTypeId))
                setSelectedCheckboxes(filter);
                setIncludedDocuments((prevState) => [
                    ...prevState,
                    { ID: item.ID },
                ]);
            } else {
                setSelectedCheckboxes((prevState) =>
                    prevState.filter(
                        (item) =>
                            !(item.DocTypeId === DocTypeId)
                    )
                );
            }
        }
        // saveAuto();
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const result1 = !_.isEmpty(includedDocuments) ? await includeDocTypes(includedDocuments) : true;
        const result2 = await excludeDocTypes(id, [...selectedCheckboxes]);
        setType(MessageType.Success)
        setMessage('Your changes have been successfully saved!')
        if (result1 && result2) {
            setTimeout(async () => {
                navigate(`/project/${id}`)
            }, 2000)
        }
    }

    const handleCancel = async () => {
        setSelectedCheckboxes([]);
        navigate(`/project/${id}`);
    }
       const handlePrint = useReactToPrint({
        content: (e) => {
          const tableStat = componentRef.current.cloneNode(true);
          const PrintElem = document.createElement('div');
          const header = `
            <div class="page-header">
              <span>${localStorage.getItem("projectTitle")}</span>
              <br>
              <span class="info">**The document(s) marked as 'Exclude' are not required.</span>
            </div>
          `;
          const tableContainer = document.createElement('div');
          tableContainer.classList.add('table-container');
          tableContainer.appendChild(tableStat);
          PrintElem.innerHTML = header;
          PrintElem.appendChild(tableContainer);
          return PrintElem;
        },
        bodyClass: "Exclude-docs",
        pageStyle: `
    @page {
      size: auto;
      margin-top: 100mm;
      margin-bottom: 20mm;
      page-break-after: always;
      padding: 40px !important;

    }
    @media print {
      body { -webkit-print-color-adjust: exact; padding: 40px !important; }
      .page-header { position: fixed; z-index: 9; top: 0; }
      .table-container {
        margin-top: 20mm; /* Adjust the margin-top value for each page as needed */
        page-break-inside: avoid;
      }
    }
  `,
      });
 
    const renderDocuments = (docCategory) => {
        return docCategory && Object.keys(docCategory).length > 0 && Object.entries(docCategory).map(([k, v], index) => {
            return <div className="finances-data" id={`documents_${index}`}>
                <h6 className='line-after pr-info pr-blue'><span>{k}</span></h6>
                {v.map((d, u) => {
                    return <>
                        <h4>{d[0].Title && d[0].Title}</h4>
                        <div className="tab-fin">
                            <table className="table fin-table tab-cl">
                                <thead>
                                    <tr>
                                        <th scope="col" className="wid-90">Document name</th>
                                        <th scope="col" className="wid-20">Exclude</th>
                                    </tr>
                                </thead>
                                <tbody className="fin-tb-body">
                                    {d.map((doc, i) => {
                                        return <tr key={i}>
                                            <td className="wt-preline cr-gry">{doc.Description}<br />
                                            </td>
                                            <td className="text-center">
                                                <div className="exclude-check">
                                                    <div className="check-data">
                                                        <div className="form-check form-check-ct">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value=""
                                                                id={`DocTypes_${i}`}
                                                                checked={selectedCheckboxes.filter((e) => e.DocTypeId === doc.ID).length > 0}
                                                                disabled={projectStatus === ProjectStatus.Completed}
                                                                onChange={async (e) => {
                                                                    await handleCheckboxChange(e, {
                                                                        DocTypeId: doc.ID
                                                                    });
                                                                }}

                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                })}
            </div>
        })

    }
    return (
        <>
            <MessageBox message={message} setMessage={setMessage} type={type} />

            <div className="container-fluid">
                <div className="overview-header ps-fixed">
                    <div className="row bt-btm">
                        <div className="col-md-12">
                            <div className="prj-header">
                                <div className="document-customheader">
                                    <h3><Link to={`/project/${id}`}><span className="iconify" data-icon="mdi:arrow-left"></span></Link>Exclude documents 
                                    <span onClick={handlePrint} className="print-icon">
                                        <Tooltip title={"Print"} placement="bottom" id="printTooltip">
                                         <Icon icon={"fluent:print-48-regular"} className="iconify"/>
                                        </Tooltip>
                                        </span>
                                    </h3>                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="document-data doc-height">
                    <div className="row">
                        {docsLoading && <Loader loading={docsLoading} height={'20rem'} width={'80rem'} />}
                        <div className="col-md-2 rit-border col-sm-3">
                            <div className="nav-menu-data" id="header" >

                                <nav id="navbar" className="navbar">
                                    <ul>
                                        {categories && _.orderBy(categories, [c => c.Title.toLowerCase()], ['asc']).map((d, index) => <li key={index}><a className={`${selected === (index + 1) ? 'nav-link scrollto active cursor-pointer' : 'nav-link scrollto cursor-pointer'}`} to="#" onClick={() => handleSelect(d.ID, index)} href={`#documents_${index}`}>{d.Title}</a></li>
                                        )}
                                        {/* <li ><a className=" nav-link scrollto active" href="#finance-id">Administrative</a></li>
                                        <li ><a className=" nav-link scrollto" href="#administrative-id">Clinical</a></li>
                                        <li ><a className=" nav-link scrollto " href="#">Corporate documents</a></li>
                                        <li ><a className=" nav-link scrollto " href="#">Finances</a></li>
                                        <li ><a className=" nav-link scrollto " href="#">Human Resources</a></li>
                                        <li ><a className=" nav-link scrollto " href="#">Payroll</a></li> */}
                                    </ul>
                                    {/* <!-- <i className="bi bi-list mobile-nav-toggle"></i> --> */}
                                </nav>
                            </div>

                        </div>
                        <div class="col-md-10 col-sm-9">
                            <div ref={componentRef}>
                                {renderDocuments(docCategory)}
                            </div>
                            {projectStatus !== ProjectStatus.Completed && <div className="col-md-12 text-right">
                                {/* <!-- <button type="button" className="btn-cst-grey" data-bs-dismiss="modal"><span className="iconify" data-icon="ion:close-circle-outline"></span>Cancel</button> --> */}
                                <button type="button" disabled={isLoading} onClick={() => handleSubmit()} className="ct-btn" data-bs-toggle="modal" data-bs-target="#view-validation">

                                    {isLoading ? <img
                                        src={image.loader.default}
                                        className={'load__icon1'}
                                        alt="loader"
                                        width={20}
                                        height={20}
                                    /> : <Icon icon="carbon:save" width={20} />}
                                    {' '}Save Changes
                                </button>
                                <a href="#" onClick={() => handleCancel()} ><button type="button" className="btn-cst-grey" data-bs-dismiss="modal"> <span className="iconify" data-icon="material-symbols:cancel-outline-rounded" data-width="20px"></span>
                                    {' '}Cancel</button></a>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    categories: state.projects && state.projects.doc_category_exclude,
    doctypes: state.projects && state.projects.doc_types,
    excludeddocs: state.projects && state.projects.excluded_docs
});
const mapDispatchToProps = { excludeDocTypes, includeDocTypes, getDocumentCategoriesForExclude, getDocumentTypes, getExcludedDocs };

export default connect(mapStateToProps, mapDispatchToProps)(DocExclude);  