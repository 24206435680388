import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Modal from "../../Task/createTaskModal";
import { getInitials } from '../../../util/helper';
import DatePicker from 'react-datepicker';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import Tooltip from '../Tooltip/index'
import moment from 'moment';
import { getTaskById } from '../../../store/projects/action';
import { connect } from 'react-redux';
import Loading from '../SpinnerLoader';


const FullCalendarView = (props) => {
  const { task, reloadFunction, handleMonthChange, TaskArray, setIsLoading, setTask, getTaskById, isLoading } = props;
  const calendarRef = useRef(null);
  const calendarIconRef = useRef(null)
  const [date, setDate] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(false);  
  const [events, setEvents] = useState([]);

  useEffect(()=>{
    setTimeout(() => {
      setEvents(task)
    }, 1000);
    setTimeout(() => { 
      setIsLoading(false)
    }, 2000);
  },[task])
 
  function renderEventContent(eventInfo) {
    return <div className='event-container'>
      <div className='event-title-container'>
        <Tooltip title={eventInfo.event.title} placement={"top"}>
          <div className='event-content'>
            <span className='event-styles'>{eventInfo.event.title.length > 16 ? eventInfo.event.title.substring(0, 15) + '...' : eventInfo.event.title} </span></div></Tooltip>      </div>
      <div className='event-Assignee-container'>
        <Tooltip title={eventInfo.event?._def?.extendedProps.AssigneeName} placement={"top"}>
          <div className="avatar-xs">
            <div className="avatar-title bg-soft rounded-circle">
              {getInitials(eventInfo.event?._def?.extendedProps.AssigneeName)}</div>
          </div>
        </Tooltip>      </div>
    </div>
  }



  const handleEventClick = async (clickInfo) => {
    let id = clickInfo?.event?._def?.publicId;
    let projectId = clickInfo?.event?._def?.extendedProps?.ProjectId;
    let findTask = await getTaskById(Number(id), projectId);
    handleOpenModal(findTask[0]);
  }
  const handleOpenModal = (findTask) => {
    setModalOpen(true);
    setSelectedTask(findTask);
    window.$(`#create-task`).modal('show');
    window.$("#create-task")[0].style.zIndex = "1051";
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    window.$(`#create-task`).modal('hide');
  };
  const handleCalendarChange = (calendarApi) => {
    setEvents([])
    setDate(calendarApi.view.currentStart)
    handleMonthChange(calendarApi.view.currentStart)
    setSelectedDate(calendarApi.view.currentStart);
  };
  const monthDropDown = (calendarIconRef) => {
    calendarIconRef.current.setOpen(true);
  }

  const handelMonthYearChange = (date) => { 
    setIsLoading(true); 
    setEvents([])
    let setMonthYear = new Date(date)
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(setMonthYear);
    setDate(setMonthYear);
    handleMonthChange(setMonthYear);
    setSelectedDate(date);
  }

  const handleSave = () => { 
    setReloadFlag(prevReloadFlag => !prevReloadFlag);
    reloadFunction(reloadFlag);
  };

  const customMoreLink = (info) => {
    return info.shortText
  }

  return ( <div className='container m-0'>  
 <div className='month-year-dropDown'>
      <DatePicker showMonthYearPicker ref={calendarIconRef} className='hidden-calendar' onChange={handelMonthYearChange} selected={selectedDate} />
    </div> 
     {isLoading && <Loading height={'50rem'} width={'80rem'}/> }
  <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin]}
      initialView={"dayGridMonth"}
      customButtons={{
        DownArrow: {
          icon: "bi bi-chevron-down",
          click: function () {
            setIsLoading(true); 
            const calendarApi = calendarRef.current.getApi();
            calendarApi.next();
            handleCalendarChange(calendarApi);
          },
        },
        UpArrow: {
          icon: "bi bi-chevron-up",
          click: function () {
            setIsLoading(true); 
            const calendarApi = calendarRef.current.getApi();
            calendarApi.prev();
            handleCalendarChange(calendarApi);
          },
        },
        Today: {
          text: 'Today',
          click: function () {
             setIsLoading(true); 
            const calendarApi = calendarRef.current.getApi();
            calendarApi.today();
            handleCalendarChange(calendarApi);
          },
        },
        dropDown: {

          text: `${moment(selectedDate || new Date()).format('MMMM YYYY')}` ,
          click: function () {
            monthDropDown(calendarIconRef);
          }
        },
      }}
      height={"70vh"}
      eventOverlap={true}
      weekends={true}
      events={events}
      eventOrder={"index"}
      eventClick={handleEventClick}
      dayMaxEvents={3}
      eventContent={renderEventContent}
      eventDisplay={'block'}
      eventBackgroundColor='#6A8BA0'
      eventBorderColor='#6A8BA0'
      dayHeaderFormat={{ weekday: 'long' }}
      dayHeaderClassNames={'dayHeaderClassNames'}
      eventClassNames={'eventClassNames'}
      moreLinkClassNames={"moreLinkClassNames"}
      moreLinkContent={customMoreLink}
      headerToolbar={
        {
          start: 'Today UpArrow,DownArrow dropDown', // will normally be on the left. if RTL, will be on the right
          center: '',
          end: '' // will normally be on the right. if RTL, will be on the left
        }}
      themeSystem='bootstrap5'
    />  
    <Modal
      onSave={handleSave}
      taskLoader={setIsLoading}
      tasks={TaskArray}
      setTasks={setTask}
      selectedTask={selectedTask}
      onClose={handleCloseModal}
      setSelectedTask={setSelectedTask} /> 
  </div>  );
}
const mapStateToProps = state => ({});
const mapDispatchToProps = { getTaskById };
export default connect(mapStateToProps, mapDispatchToProps)(FullCalendarView);
