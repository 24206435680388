import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Pagination = (props) => {
    const { items, onChangePage, currentPage, loading } = props

    const [pager, setPager] = useState({})
    const [initialPage, setInitialPage] = useState(1)
    const [pageSize, setPageSize] = useState(props.pageSize)

    const [pageRange, setPageRange] = useState("1-10");
    const totalPages = Math.ceil(items / pageSize);
    const finalPageCount = (items % pageSize);

    useEffect(() => {
        if (items > 0) {
            setPage(initialPage);
            setPageSize(pageSize);
        }
    }, [items])
    const setPage = page => {

        // get new pager object for specified page
        let pager = getPager(items, page, pageSize);

        // get new page of items from items array
        let pageOfItems =props.pageSize|| 10;

        // update state
        setPager(pager);
        if (pager.currentPage === totalPages) {
            setPageRange(`${(pager.currentPage * (props.pageSize||10)) - (props.pageSize-1||9)} - ${((pager.currentPage - 1) * (props.pageSize||10)) + finalPageCount} `)
        }
        else {
            setPageRange(`${(pager.currentPage * (props.pageSize||10)) - (props.pageSize-1||9)} - ${pager.currentPage * (props.pageSize||10)} `)
        }
        // call change page function in parent component
        onChangePage(pageOfItems, pager.currentPage);
    };

    const getPager = (totalItems, currentPage, pageSize) => {

        // default to first page
        currentPage = currentPage || 1;
        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        //let totalPages = Math.ceil(totalItems / pageSize);

        let startPage, endPage;
        if (totalPages <= 5) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = [...Array(endPage + 1 - startPage).keys()].map(
            i => startPage + i
        );

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    };
    if (!pager.pages || pager.pages.length <= 1) {
        // don't display pager if there is only 1 page
        return null;
    }
    return (
    <div className="tabpagination">
        {!loading &&  <ul>
            <li className={`${pager.currentPage === 1 && "disabledArrows"} ic-arrow`} >
                <Link href="#" className={`${pager.currentPage === 1 && "disabledArrows"} prev`} onClick={() => setPage((pager.currentPage) - 1)}>
                    <span
                        className="iconify"
                        data-icon="akar-icons:chevron-left">
                    </span></Link>
            </li>
            {pager.pages.map((page, index,) => (
                <li key={index} className={pager.currentPage === page ? "numactive" : "hidden-xs"} >

                    <Link href="#" className={pager.currentPage === page ? "active" : ""} onClick={() => setPage(page)}> {page}</Link>

                </li>
            ))}
            <li className={`ic-arrow ${pager.currentPage !== pager.totalPages ? '': 'disabledArrows'}`} >
                <Link href="#" className="next" onClick={() => pager.currentPage !== pager.totalPages && setPage(pager.currentPage + 1)}>
                    <span
                        className="iconify"
                        data-icon="akar-icons:chevron-right">
                    </span>
                </Link>
            </li>
        </ul>}
       
 {!loading &&<p>Results: {pageRange} of {items}</p>}



    </div>)
}

export default Pagination;
