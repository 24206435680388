import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {getProjectList } from "../../../store/projects/action";
import Loader from '../../../components/Common/SpinnerLoader';
import moment from 'moment';
import Pagination from '../../../components/Common/Pagination';
import { fullName, getProjectHealth, getProjectProgressColor, getProjectStatus, getTotalProgressPercentage } from '../../../util/helper';

const Onboarding = (props) => {
    const { projectList ,getProjectList} = props;
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState(false)
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getProjectList()
    }, [])

    useEffect(() => {
        if (projectList && projectList?.data) {
            setProjects(projectList?.data)
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        }
    }, [projectList])

    const navigateToProject = (id) => {
        navigate(`/project/${id}`)
    }
    const onChangePage = async (pageOfItems, pageno) => {
        setLoading(true);
        await getProjectList(pageOfItems, pageno - 1,{$isPercentage : true, $isUser:true,});
        setTimeout(() => {
            setLoading(false);
            setPagination(true);
        }, 500);
        setCurrentPage(pageno - 1);
    };

    return (
        <div>
             <div className='d-flex item-align-center'>
                {loading && <Loader loading={loading} height={'20rem'} width={'80rem'} />}
            </div>
            {!loading && (
                <div className="task-header">
                    <div className="container-fluid">
                        <div className="row mt-0">
                            <div className="col-md-12" id="colleft-data">
                                <div className="tasklist-header">
                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <button className="i-href-task"><span className="iconify" data-icon="carbon:filter"></span>
                                                Fliter</button>
                                            <button className="i-href-task"><span className="iconify" data-icon="basil:sort-outline"></span>Sort</button>
                                        </div>

                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-12 table-responsive">
                                        <table className="table task-tbl" id="project-list">
                                            <thead>
                                                <tr className="list-task-tb">
                                                    <th scope="col" className="wit-18">Name</th>
                                                    <th scope="col" className="br-left wit-14">Contact person</th>
                                                    <th scope="col" className="br-left wit-10 text-left">Start date</th>
                                                    <th scope="col" className="br-left wit-10 text-left">Due date</th>
                                                    <th scope="col" className="br-left wit-10 text-left">Status</th> 
                                                    <th scope="col" className="br-left wit-16 text-left">Health</th>
                                                    <th scope="col" className="br-left">Profile progress</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    projects && projects.map((project) => { 
                                                        let ContactName = project && project?.WMS_ProjectFacilityUserAccess?.find((i)=> i.Title === 1);
                                                        let Completename = fullName({...ContactName});
                                                         return (
                                                            <tr className="list-task-tb prj-list cursor-pointer" onClick={() => navigateToProject(project.ID)} key={project.ID}>
                                                                <td className=""><div className="mt-8-tp"><Link>{project && project?.Title}</Link></div></td>
                                                                <td className="br-left"><div className="mt-8-tp">{Completename}</div></td>
                                                                <td className="br-left"><div className="mt-8-tp text-left">{moment(project.CreatedAt).format("MMM DD, YYYY")}</div></td>
                                                                <td className="br-left"><div className="mt-8-tp text-left">{project && project.EndDate ? moment(project.EndDate).format("MMM DD, YYYY") : "--"}</div></td>
                                                                <td className="br-left ">
                                                                    <div className={getProjectStatus(project.Status)?.color}>
                                                                        <span className="iconify" data-icon="octicon:dot-fill-16"></span>
                                                                        {getProjectStatus(project.Status)?.name}
                                                                    </div>
                                                                </td>
                                                                <td className="br-left d-flex"><div className={`prj-btn ${getProjectHealth(project.Health).color} `}>
                                                                    <span className="iconify" data-icon="octicon:dot-fill-16"></span>
                                                                    {getProjectHealth(project.Health).name}
                                                                </div></td>
                                                                <td className="br-left ">
                                                                    <div className="prj-progressbar">
                                                                        <div className="progress progress-lg">
                                                                            <div className={getProjectProgressColor(project.Health).color}
                                                                                role="progressbar" style={{ width: getTotalProgressPercentage(project?.WMS_ProjectCompletionPercentage) + '%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div><span className={`pgr px-2`}> {getTotalProgressPercentage(project?.WMS_ProjectCompletionPercentage)}%</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            <div className='pagination-container'>
                <Pagination className="" pageSize={10} currentPage={currentPage} items={projectList && projectList?.total} onChangePage={onChangePage} loading={loading} />
        </div>
            
        
         
        </div>       

    );
}

const mapStateToProps = state => ({
    projectList: state && state.projects.projects
});
const mapDispatchToProps = {getProjectList};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding); 