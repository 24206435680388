import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {getProjectList,getAllTaskDashBoard } from "../../store/projects/action"; 
import _ from 'lodash';
import Projects from '../../components/Projects';
import Activities from '../../components/Activities';
import Loader from '../../components/Common/SpinnerLoader'
import AddProject from '../../components/AddProject';
import {TaskDateFilter, TaskStatus} from '../../util/enum'
import {AutoRecallProvider, useAutoRecalled} from '../../hooks/useAutoRecalled'

const DashboardMain = (props) => {
    const {projectList,getProjectList,getAllTaskDashBoard } = props;
    const userDetails = useSelector((state) => state.auth);
    const tempname = userDetails?.profile?.UserName;
    const userName = tempname?.charAt(0).toUpperCase() + tempname?.slice(1) || "";
    const d = new Date();
    const m = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = weekday[d.getDay()];
    const month = m[d.getMonth()];
    const date = d.getDate();
    const dateDetails = (day + ", " + month + " " + date);
    const [warming, setWarming] = useState("");
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [filterDate, setFilterDate] = useState(TaskDateFilter.getValueByName('This week'));
    const [activeCount,setActiveCount] = useState(0)
    const [overdueCount,setOverDueCount] = useState(0)
    const {autoCalled} = useAutoRecalled();

    useEffect(() => {
        const currentDate = new Date();
        const gethours = currentDate.getHours();
        if (gethours >= 12 && gethours < 17) {
            setWarming("Afternoon")
        } else if (gethours >= 17 && gethours <= 24) {
            setWarming("Evening")
        } else {
            setWarming("Morning")
        }
    }, [])

    useEffect(()=>{
        getProjectList()
    },[])

    useEffect(() => {
      const fetch = async () => {
        const active = await getAllTaskDashBoard(
          {
            currentDate: new Date(),
            $taskFilter: filterDate,
            $isOverDue: false,
            $isDashSummary: true,
          },
          10
        );
        if (active) {
          setActiveCount(active.total);
        }
      };
      fetch();
    }, [filterDate, autoCalled]);

    useEffect(() => {
      const fetch = async () => {
        const active = await getAllTaskDashBoard(
          {
            currentDate: new Date(),
            $taskFilter: filterDate,
            $isOverDue: true,
            $isDashSummary: true,
          },
          10
        );
        if (active) {
          setOverDueCount(active.total);
        }
      };
      fetch();
    }, [filterDate, autoCalled]);

  

    useEffect(()=>{
        if(projectList && projectList.data)
        {
            setProjects(projectList.data)
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }

    },[projectList])

  


    return (
      <div className="container-fluid">
        <AddProject />
        <div className="row">
          <div className="col-md-12 text-center">
            <h4 className="date-data">{dateDetails}</h4>
            <h2 className="main-data">
              Good {warming}, {userName}
            </h2>
            <div className="con-menu">
              <ul className="list-group list-group-horizontal">
               
           
                    <select
                      className="list-group-item"
                      aria-label="Filter select"
                      required=""
                      id="business-process"
                      value={parseInt(filterDate)}
                      onChange={(e) => setFilterDate(parseInt(e.target.value))}
                    >
                      {TaskDateFilter.getKeyValuePairs().map((option) => (
                        <option className='menu-content line-dt' key={option.value} value={option.value}>
                          {option.name}
                          <Icon
                            icon={"ic:sharp-keyboard-arrow-down"}
                            className="iconify"
                          />
                        </option>
                      ))}
                    </select>

                <li className="list-group-item">
                  <div className="menu-content">
                    <Icon icon={"mdi:tick"} className="iconify cus-icon" />
                    <span className="bld-data">{activeCount}</span>Task completed
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="menu-content">
                    <Icon icon={"bi:people"} className="iconify cus-icon" />
                    <span className="bld-data">{overdueCount}</span>Task overdue
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {loading && (
          <Loader loading={loading} height={"20rem"} width={"80rem"} />
        )}
        {!loading &&
          (_.isEmpty(projects) ? (
            <div className="prj-data">
              <div className="lin-dt">
                <h2>Projects </h2>
              </div>
              <div className="crt-project text-center">
                <div className="dsh-border">
                  <Link data-bs-toggle="modal" data-bs-target="#createproject">
                    <Icon icon={"material-symbols:add"} className="iconify" />
                  </Link>
                </div>
                <Link data-bs-toggle="modal" data-bs-target="#createproject">
                  <h2>Create Project</h2>
                </Link>
              </div>
            </div>
          ) : (
            <div className="row">             
                <Projects list={projects} />
                <Activities filterDate={filterDate} />
            </div>
          ))}
      </div>
    );
}

const mapStateToProps = state => ({
    projectList: state && state.projects.projects
});
const mapDispatchToProps = {getProjectList,getAllTaskDashBoard };

export default connect(mapStateToProps, mapDispatchToProps)(AutoRecallProvider(DashboardMain)); 