import React  from "react"; 

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function App({ children, title, placement, id }) {
  const renderTooltip = props => (
    <Tooltip id={id || "tooltipid"}{...props}> {title} </Tooltip>
  );

  return (<>
    {children &&
      <OverlayTrigger placement={placement} overlay={renderTooltip}>
        {children}
      </OverlayTrigger>}
  </>
  );
}