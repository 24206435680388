import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { openDocument } from "../../../store/projects/action";
import { Modal, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { default as URLParse } from 'url-parse';
import { saveAs } from "file-saver";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Loader from '../SpinnerLoader';
import Tooltip from "../Tooltip";
import img from "../../../assets/img";


function DocxViewer({ url }) {
  return (
    <>
      <div className="docx-container">
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
          width="100%"
          height="600"
          frameBorder="0"
        >
          <a target="_blank" href="https://office.com"></a><a target="_blank" href="https://office.com/webapps"></a>.
        </iframe>
      </div></>
  );
}

function ImageViewer({ url }) {
  const DocRef = useRef();
  const handlePrint =
    useReactToPrint({
      content: (e) => {
        const tableStat = DocRef.current.cloneNode(true);
        const PrintElem = document.createElement('div');
        const tableContainer = document.createElement('div');
        tableContainer.classList.add('image-container');
        tableContainer.appendChild(tableStat);
        PrintElem.appendChild(tableContainer);
        return PrintElem;
      },
    });
  return (
    <>
      <Tooltip title={"Print"} placement="top" id="printTool">
        <Button className="doc-view-button f-btn" onClick={handlePrint}>
          <Icon
            icon={"material-symbols:print"}
            className="iconify" />
        </Button>
      </Tooltip>
      <div ref={DocRef} className="image-container">
        <img style={{ width: "1000px", height: "500px" }} src={url} alt="Document Viewer" className="image-preview" />
      </div>
    </>
  );
}

function PdfViewer({ url }) {
  useEffect(() => {
    const iframe = document.getElementById("pdf-iframe");
    if (iframe) {
      iframe.onload = () => {
        iframe.contentWindow.document.body.style.zoom = "100%";
      };
    }
  }, [url]);

  return (
    <div className="pdf-container">
      <iframe
        src={url}
        title="Document Viewer"
        className="pdf-iframe"
        toolbar="0"
        navigation="0"
      ></iframe>
    </div>
  );
}

function XlsxViewer({ url }) {
  return (
    <>
      <div className="excel-container">
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
          width="100%"
          height="600"
          frameBorder="0">
          <a target="_blank" href="https://office.com"></a><a target="_blank" href="https://office.com/webapps"></a>.
        </iframe>
      </div></>
  );
}

function DownloadViewer({ url }) {
  return (
    <>
      <div className="excel-container">
        <h4 className="mt-5">Please check your downloads folder as the document has been downloaded there.</h4>
        <img src={img.gifdownload} height={200}></img>
        <iframe
          src={url}
          title="Document Viewer"
          className="pdf-iframe"
          toolbar="0"
          navigation="0"
        ></iframe>
      </div></>
  );
}

function PPTViewer({ url }) {
  return (
    <>
      <div className="excel-container">
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`}
          width="100%"
          height="600"
          frameBorder="0">
          <a target="_blank" href="https://office.com"></a><a target="_blank" href="https://office.com/webapps"></a>.
        </iframe>
      </div></>
  );
}

function DocumentViewer({ docCategory, facilityDocsCategory, documentURL, open, setOpen, name, openDocument, entireDocuments, documentID, category, DocTypeId, docType }) {
  const urls = entireDocuments.map((item) => item.signedUrl);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allDoc, setAllDoc] = useState([])
  const [documentCategory, setDocumentCategory] = useState(null);
  const [documentType, setDocumentType] = useState(null);

  const fechDoc = async () => {
    let docUrl = await openDocument(documentURL);
    setAllDoc(docUrl)
  }
  const getCategories = (item) => {
    let docTitle, docDesc;
    Object.entries(docCategory)?.map(([category, documents], i) => {
      documents.map((document, i) => {
        document.map((obj) => {
          if (obj['ID'] == item) {
            docTitle = obj.WMS_DocCategory['Title']
            docDesc = obj['Description']
            return {
              docTitle,
              docDesc
            }
          }
        })
      })
    }
    )
    if (!docTitle &&
      !docDesc) {
      facilityDocsCategory[0]?.WMS_DocTypes.map((e) => {
        if (e.ID == item) {
          docTitle = facilityDocsCategory[0].Title
          docDesc = e['Description']
          return {
            docTitle,
            docDesc
          }
        }
      })
    }
    setDocumentCategory(docTitle)
    setDocumentType(docDesc)
  }
  useEffect(() => {
    setLoading(true)
    fechDoc()
    getCategories(docType)
    if (documentID) {
      const findDocumentIndex = entireDocuments.findIndex((doc) => doc.ID === documentID);
      if (findDocumentIndex !== -1) {
        setActiveDocumentIndex(findDocumentIndex);
        setCurrentPage(findDocumentIndex);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [open]);

  const handleClose = async () => {
    setCurrentPage(1);
    setOpen(false);
  };
  const goToPreviousDocument = async () => {
    if (activeDocumentIndex > 0) {
      setLoading(true);
      setActiveDocumentIndex(activeDocumentIndex - 1);
      getCategories(entireDocuments[activeDocumentIndex - 1].DocTypeId)
      let docUrl = await openDocument(entireDocuments[activeDocumentIndex - 1].DocExtURL);
      setAllDoc(docUrl)
      getCategories(entireDocuments[activeDocumentIndex - 1].DocTypeId)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const goToNextDocument = async () => {
    if (activeDocumentIndex < entireDocuments.length - 1) {
      setLoading(true);
      setActiveDocumentIndex(activeDocumentIndex + 1);
      getCategories(entireDocuments[activeDocumentIndex + 1].DocTypeId)
      let docUrl = await openDocument(entireDocuments[activeDocumentIndex + 1].DocExtURL);
      setAllDoc(docUrl)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const isPreviousDocumentButtonDisabled = activeDocumentIndex <= 0;
  const isNextDocumentButtonDisabled = activeDocumentIndex >= entireDocuments.length - 1;

  function extractDocumentName(url) {
    const parsedUrl = new URLParse(url);
    const path = parsedUrl.pathname;
    const segments = path.split('/');
    const documentName = segments[segments.length - 1];
    const Docname = documentName.replace(/^\w+_/, '')
    return Docname;
  }

  const documentName = extractDocumentName(allDoc.signedUrl);
  function getExtension(str) {
    return str.slice(-4);
  }
  const fileExtension = getExtension(documentName);
  const isDocxFile = fileExtension === 'docx';
  let ViewerComponent = DownloadViewer;

  if (isDocxFile) {
    ViewerComponent = DocxViewer;
  } else if (fileExtension === '.jpg' || fileExtension === '.jpeg' || fileExtension === '.png') {
    ViewerComponent = ImageViewer;
  } else if (fileExtension === 'xlsx') {
    ViewerComponent = XlsxViewer;
  } else if (fileExtension.includes('csv')) {
    ViewerComponent = DownloadViewer
  }
  else if (fileExtension.includes('pptx')) {
    ViewerComponent = PPTViewer;
  }
  else {
    ViewerComponent = PdfViewer;
  }
  const isPdfFile = fileExtension === '.pdf';

  const handleDownloadPDF = async (url, docname) => {
    let docUrl = await openDocument(url);
    if (docUrl && docUrl.signedUrl) {
      const fileName = entireDocuments[activeDocumentIndex].DocName || 'document';
      const link = document.createElement('a');
      link.href = docUrl.signedUrl;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(docUrl.signedUrl);
    }
  }
  const showDownloadButton = !isPdfFile;
  return (
    <Modal data-bs-keyboard="false" backdrop="static" size="xl" show={open} onHide={handleClose} className="doc-viewer-modal" >
      <Modal.Body className="text-center md-bd-h" id="doc-scroll">
        {loading && <Loader height={'35rem'} width={'60rem'} />}
        <div className="modal-header r-hd-sticky row">
          <div className="file-name col-md-5">
            {documentCategory || category} / {documentType || docType} / {entireDocuments[activeDocumentIndex]?.DocName || documentName}
          </div>
          <div className="doc-nav col-md-6" >
            <div className="row">
              <div className="d-flex col-md-7 pf-hed-cus">
                <p id="doc-nav-info">
                  Document {activeDocumentIndex + 1} of {urls.length}
                </p>
                <Tooltip title={"Previous"} placement="top" id="printTool">
                  <Button
                    className="doc-view-button"
                    disabled={isPreviousDocumentButtonDisabled}
                    onClick={goToPreviousDocument}
                  >
                    <Icon icon={"zondicons:arrow-left"} className="iconify" />
                  </Button>
                </Tooltip>
                <Tooltip title={"Next"} placement="top" id="printTool">
                  <Button
                    className="doc-view-button"
                    disabled={isNextDocumentButtonDisabled}
                    onClick={goToNextDocument}
                  >
                    <Icon icon={"zondicons:arrow-right"} className="iconify" />
                  </Button>
                </Tooltip>

              </div>
              <div className="d-flex col-md-4">
                {showDownloadButton && (
                  <>
                    <Tooltip title={"Download"} placement="top" id="printTool">
                      <Button className="doc-view-button" onClick={() => { handleDownloadPDF(entireDocuments[activeDocumentIndex].DocExtURL || allDoc.signedUrl, entireDocuments[activeDocumentIndex].DocName) }}>
                        <Icon
                          icon={"material-symbols:download"}
                          className="iconify" />
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </div>
          <button type="button" onClick={handleClose} className="btn-close">
            <span className="iconify" data-icon="material-symbols:close"></span>
          </button>
        </div>
        <ViewerComponent url={allDoc.signedUrl} />
      </Modal.Body>
      <Modal.Footer ></Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  url: state.projects.documentUrl,
  docName: state.projects.documentName,
});

const mapDispatchToProps = { openDocument };

export default connect(mapStateToProps, mapDispatchToProps)(DocumentViewer);
