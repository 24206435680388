import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../../components/Common/SpinnerLoader';
import { getAllTask } from '../../../store/projects/action';
import Pagination from '../../../components/Common/Pagination';
import { getInitials } from '../../../util/helper';
import moment from 'moment';
import { Icon } from '@iconify/react';



const OnboardingTask = (props) => {
    const { getAllTask } = props
    const params = useParams();
    const { id } = params;
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState([])
    const [pagination, setPagination] = useState(false)
    const [currentPage, setCurrentPage] = useState(null);
    const navigate = useNavigate();
    const navigateToTask = (id) => {
        navigate(`/project/${id}`,{state:{tab:2}})
    }
    const fetchTasks = async () => {
        try {
            const tasks = await getAllTask()
            if (tasks) {
                setTask(tasks);
                setTimeout(() => {
                    setLoading(false)
                    setPagination(true);
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchTasks()
    }, [])
    const onChangePage = async (pageOfItems, pageno) => {
        setLoading(true);

      let res=  await getAllTask(pageOfItems, pageno - 1);
      if(res){
        setTask(res)
      }
        setTimeout(() => {
            setLoading(false);
            setPagination(true);
        }, 500);
        setCurrentPage(pageno - 1);
    };
 

    return (
        <div>
            <div className='d-flex item-align-center'>
                {loading && <Loader loading={loading} height={'20rem'} width={'80rem'} />}
            </div>
            {!loading && (
                <div className="task-header">
                    <div className="container-fluid">
                        <div className="row mt-0">
                            <div className="col-md-12" id="colleft-data">
                                {/* <div className="tasklist-header">
                                    <div className="row">
                                    <div className="col-md-12">
                                        <button disabled={true} className="i-href-task"><span className="iconify" data-icon="carbon:filter"></span>
                                            Fliter</button>
                                        <button disabled={true} className="i-href-task"><span className="iconify" data-icon="basil:sort-outline"></span>Sort</button>
                                    </div>

                                    </div>
                                </div> */}
                                <div className="row pr-list">
                                    <div className="col-md-12">
                                        <table className="table task-tbl" id="task-list">
                                            <thead>
                                                <tr className="list-task-tb">
                                                    <th scope="col" className="wit-10">ID</th>
                                                    <th scope="col" className="br-left">Name</th>
                                                    <th scope="col" className="br-left">Project Name</th>
                                                    <th scope="col" className="br-left wit-10">Status</th>
                                                    <th scope="col" className="br-left">Assignee</th>
                                                    <th scope="col" className="br-left wit-10">Schedule</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {task&&task.data.map((taskItem,index) => (
                                                    <tr style={{cursor:"pointer"}} className="list-task-tb prj-list" onClick={() => navigateToTask(taskItem?.ProjectId)} key={index}>
                                                        <td className=""><div>{taskItem?.ProcessTaskNum}</div></td>
                                                        <td className="br-left">
                                                            <div className="flex-grow-1 overflow-hidden me-4 d-flex">
                                                            <Icon style={{marginRight:"5px"}} width={"15px"} icon={"mdi:check-circle-outline"} className="iconify" />
                                                               <div className="nm-li">{taskItem?.Title}</div> 
                                                            </div>
                                                        </td>
                                                        <td className="br-left">
                                                            <div className="mt-8-tp">
                                                                    <a>{taskItem?.WMS_Projects?.Title}</a>                                                              
                                                            </div>
                                                        </td>
                                                        <td className="br-left">
                                                            <div className="ble-btn prj-btn">
                                                            <Icon style={{ color:taskItem?.WMS_TaskStatus?.HEX }}  icon={"octicon:dot-fill-16"} className="iconify" />
                                                                {taskItem?.WMS_TaskStatus?.Name}
                                                            </div>
                                                        </td>
                                                        <td className="br-left wit-25 dt-data">
                                                            <div className="flex-data">
                                                                <div className="avatar-xs">
                                                                    <div className="avatar-title bg-soft rounded-circle">
                                                                        {getInitials(taskItem?.AspNetUsers?.UserName)}

                                                                    </div>
                                                                </div>
                                                                <span> {taskItem?.AspNetUsers?.UserName}</span>
                                                            </div>
                                                        </td>
                                                        <td className="br-left">
                                                            <div>
                                                                {taskItem?.StartDate ? moment(taskItem?.StartDate).format('MMM DD') : moment(taskItem?.ExpectedStartDate).format('MMM DD')} - {taskItem?.EndDate ? moment(taskItem?.EndDate).format('MMM DD') : moment(taskItem?.ExpectedEndDate).format('MMM DD')}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {pagination &&   <div className='pagination-container row view-pager'>
                <Pagination className="" pageSize={50} currentPage={currentPage} items={task && task?.total} onChangePage={onChangePage} loading={loading} />
            </div>}
          

        </div>

    );
}

const mapStateToProps = state => ({
    projectList: state && state.projects.projects
});
const mapDispatchToProps = { getAllTask };

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTask); 