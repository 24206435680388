import React from 'react'
import { getAllTaskBetweenDates } from '../../store/projects/action';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import FullCalendarView from '../../components/Common/FullCalendar';

function GlobalCalendar(props) {
    const { getAllTaskBetweenDates} = props
    const [task, setTask] = useState([])
    const [date, setDate] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [TaskArray, setTaskArray]= useState([]);
    const [reload, setReload] = useState();

    const reloadFunction =(res)=>{
      setIsLoading(true);
      setReload(res)
    }
    
    useEffect(() => { 
        const fetchData = async () => {
          try {
            const response = await getAllTaskBetweenDates(date);
            setTaskArray(response);
            if (response) {
              let displayResponse = response.map((item) => {
              let ProcessTaskNum = item?.ProcessTaskNum.split("/")[2];
                return {
                  index: ProcessTaskNum || "",
                  id: item?.ProjectTaskId,
                  title: item?.Title,
                  start: item?.TaskStartDate,
                  end: item?.TaskEndDate,
                  ProjectId: item?.ProjectId,
                  AssigneeName: item?.UserName
                }
              })
              setTask(displayResponse); 
            }
          } catch (error) {
            console.log('Error fetching tasks:', error);
          }
        };
        fetchData();
      }, [date, reload, getAllTaskBetweenDates]);

      const handleMonthChange = (date) => {
        setDate(date);
      };
  return (
    <div className="container-fluid">
      <div className="overview-header">
        <div className="row bt-btm">
          <div className="col-md-12">
            <div className="prj-header">
              <h4 className='mb-4'>Calendar</h4>
            </div>
          </div>
        </div>
      </div>
    <div className='fullcalendar-container container'>
       <FullCalendarView reloadFunction={reloadFunction} isLoading={isLoading} setTask={setTask} setIsLoading={setIsLoading} task={task} handleMonthChange={handleMonthChange} calendarType={"Task"} TaskArray={TaskArray} />
      </div>
    </div>

  )
}
const mapStateToProps = state => ({ });
const mapDispatchToProps = { getAllTaskBetweenDates }; 
export default connect(mapStateToProps, mapDispatchToProps)(GlobalCalendar);
