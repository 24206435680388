import { Icon } from "@iconify/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
import Loader from "../Common/SpinnerLoader";

const ScrollComponent = ({ data, loading, handleScroll, tab, navigateToTask, getInitials, Overdue }) => {
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [renderSecond, setRenderSecond] = useState(false);

  useEffect(() => {
    if (!renderSecond) {
      setRenderSecond(true);
    } else {
      if (tab && handleScroll) handleScroll(tab);
    }
  }, [shouldLoadMore]);

  const handleScrollEvent = async (event) => {
    const scrollContainer = event.target;
    const scrollThreshold =
      scrollContainer.scrollHeight - scrollContainer.clientHeight - 200; // Adjust the threshold value as needed

    if (scrollContainer.scrollTop >= scrollThreshold) {
      if (data && data.skip <= data.total) setShouldLoadMore((prev) => !prev);
    } else {
      // do nothing
    }
  };

  const formattedDaysOverdue = ({TaskEndDate}) => {
    const CurrentDate = new Date()
    const startDate = new Date(TaskEndDate);
    const endDate = new Date(CurrentDate);
    const millisecondsDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = Math.abs(Math.floor(millisecondsDiff / (1000 * 3600 * 24)));
    return `${daysDiff} days`;

  };

  const SkeletonLoader = () => {
    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
      <tr key={index}>
        <td>
          <div className="sm-task-list">
            <div className="d-flex">
              <div className="track-btn"></div>
              <div className="">
                <ContentLoader
                  speed={2}
                  width={150}
                  height={20}
                  viewBox="0 0 150 20"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
                </ContentLoader>
              </div>
            </div>
            <h4>
              <ContentLoader
                speed={2}
                width={200}
                height={20}
                viewBox="0 0 200 20"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="200" height="20" />
              </ContentLoader>
            </h4>
          </div>
        </td>
        <td>
          <div className="ft-right">
            <div className="flex-data">
              <div className="avatar-xs"></div>
              <span>
                <ContentLoader
                  speed={2}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                </ContentLoader>
              </span>
            </div>
            <div className="lab-dt">
              <ContentLoader
                speed={2}
                width={100}
                height={20}
                viewBox="0 0 100 20"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
              </ContentLoader>
            </div>
          </div>
        </td>
      </tr>
    ));

    return <>{skeletonRows}</>;
  };
  return loading ? (
    <table className="table summary-tab">
      <tbody>{SkeletonLoader()} </tbody>
    </table>
  ) : data.total === 0 ? (
    <table className="table summary-tab">
      <tbody>
        <tr>
          <td colSpan="2">No Tasks to Display</td>
        </tr>
      </tbody>
    </table>
  ) : (
    <InfiniteScroll
      className="scrollcontainer"
      height={"360px"}
      dataLength={(data && data.total) || 0} //This is important field to render the next data
      // next={async () => await handleScroll(tab)}
      // hasMore={data && data.data && data.data.length < data.total}
      loader={<Loader height={"35rem"} width={"50rem"} />}
      style={{ overflow: "auto" }}
      onScroll={handleScrollEvent}
    >
      <table className="table summary-tab">
        <tbody>
          {data?.data?.map((task, index) => (
            <tr
              style={{ cursor: "pointer" }}
              onClick={() => navigateToTask(task)}
              key={index}
            >
              <td>
                <div className="sm-task-list">
                  <div className="d-flex">
                    <div className="track-btn">
                      <div className="d-flex">
                        <Icon
                          style={{ color: task?.Hex }}
                          icon={"octicon:dot-fill-16"}
                          className="iconify"
                        />

                        {task?.ProcessTaskNum}
                      </div>
                    </div>
                    <div className="prj-nme">{task?.ProjectTitle}</div>
                  </div>
                  <h4>{task?.Title}</h4>
                </div>
              </td>
              <td>
                <div className="ft-right">
                  <div className="lab-dt">
                    {tab === Overdue && (
                      <div className="lab-dt-overdue">
                        overdue by {formattedDaysOverdue(task)}
                      </div>
                    )}
                  </div>
                  <div className="flex-data">
                    <div className="avatar-xs">
                      <div className="avatar-title bg-primary text-primary bg-soft rounded-circle">
                        {getInitials(task?.UserName)}
                      </div>
                    </div>
                    <span> {task?.UserName}</span>
                  </div>
                  <div className="lab-dt">
                    {task?.TaskStartDate && moment(task?.TaskStartDate).format("MMM DD")}{" "}
                    -{" "}{task?.TaskEndDate && moment(task?.TaskEndDate).format("MMM DD")}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </InfiniteScroll>
  );
};

export default ScrollComponent;
