import React, { useState } from 'react';
import { MessageType, Priority, TaskStatus } from '../../util/enum';
import schema from './schema';
import validate from "validate.js";
import TextInput from "../Common/TextInput";
import DatePickerComponent from '../DatePicker';
import moment from 'moment';
import MessageBox from '../Common/MessageBox';
import { getInitials, getPriority, getProjectStatus } from '../../util/helper';
import { editTask, getAllUsers, getTaskByProjectId, getAllStatus, getAllTaskDashBoard, getProjectById} from '../../store/projects/action';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import Loader from '../../components/Common/SpinnerLoader';
import _ from 'lodash';
import { differenceInCalendarDays  } from 'date-fns'

const Modal = (props) => {
    const { onSave, selectedTask, taskLoader, editTask, getTaskByProjectId, setTasks, getAllUsers, getAllStatus, setSelectedTask,  setAutoCalled,getProjectById  } = props;
    const [showAttachments, setShowAttachments] = useState(true);
    const [options, setOptions] = useState([])
    const params = useParams();
    const { id } = params;

    const initialState = {
        values: {
            ID: "",
            Title: "",
            Description: "",
            Assignee: "",
            Status: "",
            Priority: "",
            StartDate: "",
            EndDate: "",
            WMS_ProjectDependentTasks: "",
            ProcessTaskNum: "",
            ExpectedEndDate: "",
            ExpectedStartDate: "",
        },
        errors: {
            Title: "",
            Description: "",
            Assignee: "",
            Status: "",
            Priority: "",
            StartDate: "",
            EndDate: "",
            WMS_ProjectDependentTasks: "",
            ProcessTaskNum: "",
            ExpectedEndDate: "",
            ExpectedStartDate: ""
        },
    };

    const [values, setValues] = useState(initialState.values);
    const [errors, setErrors] = useState(initialState.errors);
    const [type, setType] = useState();
    const [message, setMessage] = useState();
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isEdited, setIsEdited] = useState(false)

    useEffect(() => {
        const fetch = async() =>{
          
           
            if (selectedTask && !_.isEmpty(selectedTask)) {
                setValues({
                    ID: selectedTask?.ID || "",
                    Title: selectedTask?.Title || "",
                    Description: selectedTask?.Description || "",
                    Assignee: selectedTask?.Assignee || "",
                    Status: selectedTask?.WMS_TaskStatus?.ID || "",
                    Priority: selectedTask?.Priority || "",
                    StartDate: selectedTask.StartDate ? selectedTask?.StartDate : selectedTask?.ExpectedStartDate || null,
                    EndDate: selectedTask.EndDate ? selectedTask?.EndDate : selectedTask?.ExpectedEndDate || null,
                    WMS_ProjectDependentTasks: selectedTask?.WMS_ProjectDependentTasks || "",
                    ProcessTaskNum: selectedTask?.ProcessTaskNum || "",
                    ExpectedEndDate: selectedTask?.ExpectedEndDate || "",
                    ExpectedStartDate: selectedTask?.ExpectedStartDate || "",
                })
           
            }

        }
        fetch()
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
      

    }, [selectedTask])

    const handleChange = (field, value) => {
        if (field == "Assignee") {
            setValues((prev) => ({ ...prev, [field]: value?.value || null }));
        } else {
            setValues((prev) => ({ ...prev, [field]: value }));
        }
        setIsEdited(true)
    }

    const validateForm = () => {
        let errors = validate(values, schema);
        setErrors(errors || {});
        let valid = errors ? false : true;
        if (!valid) {
            setType(MessageType.Error);
            setMessage(errors[Object.keys(errors)[0]][0]);
        } else {
            setErrors({});
        }
        return valid;
    }

    const handleTask = async () => {
        try {
            let isValid = validateForm()
            if (isValid) {
                if (id || selectedTask?.ProjectId) {
                    taskLoader(true)
                    setTasks([])
                    let data = { ...values }
                    delete data.WMS_ProjectDependentTasks
                    delete data.WMS_Project
                    const result = await editTask(data)
                    if (result) {
                        setAutoCalled && setAutoCalled(result) //This is for refreshing the activities from dashboard
                        setType(MessageType.Success);
                        setMessage("Your changes have been successfully saved!");
                        setIsEdited(false)
                        setIsLoading(false)
                        window.$(`#create-task`).modal('hide');
                        setTimeout(async () => {
                            setIsLoading(false)
                            const res = await getTaskByProjectId(Number(id || selectedTask?.ProjectId))
                            setTasks(res)
                            taskLoader(false)
                            window.$(`#create-task`).modal('hide');
                            onSave();
                        }, 1000);
                    }
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    
    const handleModalSave = async () => {
        let isValid = validateForm()
        if (isValid) {
            window.$(`#create-task`).modal('hide');
            window.$(`#closeconfirmation-modal`).modal('hide');
            await handleTask()
        } else {
            window.$(`#closeconfirmation-modal`).modal('hide');
            window.$("#create-task")[0].style.zIndex = "1051";

        }
    }
    const handleModalClose = () => {
        window.$(`#create-task`).modal('hide');
        window.$(`#closeconfirmation-modal`).modal('hide');
        setIsEdited(false)
        const modalContent = document.querySelector('#create-task');
        if (modalContent) {
            modalContent.scrollTop = 0;
        }
        if (isEdited) {
            setValues({})
            setSelectedTask([])
            setErrors({});
        }
        else {
            setIsEdited(false)
        }
    }

    const priority = Object.keys(Priority).map((key) => ({
        value: Priority[key],
        label: key
    }));

    const loadStatus = async () => {
        const res = await getAllStatus()
        if (res) {
            setOptions(res)
        }
    }

    const loadOptions = async () => {
        const res = await getAllUsers()
        if (res && res.length) {
            let users = res.map((u) => {
                return { value: u.Id, label: u.UserName }
            })
            setUsers(users)
        }
    }
    useEffect(() => {
        loadOptions()
        loadStatus()
    }, [])
    const CaretDownIcon = () => {
        return <span className="iconify date-icon" data-icon="material-symbols:search" data-width="20"></span>
            ;
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        );
    }

    const getDuration = ({ StartDate, EndDate, ExpectedStartDate, ExpectedEndDate }) => {
        const TaskStart = new Date(StartDate ? StartDate : ExpectedStartDate || null);
        const TaskEnd = new Date(EndDate ? EndDate : ExpectedEndDate || null);
        const daysDiff = Math.abs(differenceInCalendarDays(TaskEnd, TaskStart));
        return `${daysDiff} days`;
    };


    const handleConfirmClose = () => {
        if (isEdited) {
            window.$("#create-task")[0].style.zIndex = "1050";
            window.$(`#closeconfirmation-modal`).modal('show');
        }
        else {
            window.$(`#create-task`).modal('hide');
        }
    }
    return (
        <>
            {<>
                <MessageBox message={message} setMessage={setMessage} type={type} />
                <div className="modal fade task-modal" data-bs-keyboard="false" id="create-task" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="staticBackdropLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-xl modal-dialog-centered	">
                        {isLoading && <Loader loading={isLoading} height={'5rem'} width={'80rem'} />}
                        <div className="modal-content">

                            <div className="modal-header">
                                <h3 className="dependency-title" id="staticBackdropLabel">
                                    {selectedTask?.Title}
                                </h3>
                                <div className="hd-right" style={{ float: "right" }}>
                                    <button onClick={handleConfirmClose} type="button" className="btn-cr-grey" >
                                        <span className="iconify" data-icon="ion:close-circle-outline" />
                                        Cancel
                                    </button>
                                    <button onClick={handleTask} className="i-href-task-blue" >
                                        <span style={{ marginRight: "5px" }} className="iconify" data-icon="mdi:tick-circle-outline" />
                                        Save
                                    </button>
                                    <button onClick={handleConfirmClose} type="button" className="btn-close" />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="task-data">
                                        <div className="sticky-header">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row d-flex">
                                                        <div className="wt-14-2">
                                                            <div className="cr-hed">
                                                                <h6>Assignee</h6>
                                                                <div className="flex-data">
                                                                    <div className="avatar-xs">
                                                                        <div className="avatar-title bg-soft rounded-circle">
                                                                            {getInitials(users?.find(u => u.value === values?.Assignee)?.label)}
                                                                        </div>
                                                                    </div>
                                                                    <span>{users?.find(u => u.value === values?.Assignee)?.label}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wt-14-2">
                                                            <div className="cr-hed">
                                                                <h6>Status</h6>
                                                                <div className="prj-btn">
                                                                    <Icon style={{ color: selectedTask?.WMS_TaskStatus?.HEX }} icon={"octicon:dot-fill-16"} className="iconify" />
                                                                    {selectedTask?.WMS_TaskStatus?.Name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wt-14-2">
                                                            <div className="cr-hed">
                                                                <h6>Priority</h6>
                                                                <p>{getPriority(selectedTask?.Priority)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="wt-14-2">
                                                            <div className="cr-hed">
                                                                <h6>Schedule</h6>
                                                                <p>{selectedTask?.StartDate ? moment(selectedTask?.StartDate).format('MMM DD') : moment(selectedTask?.ExpectedStartDate).format('MMM DD')} - {selectedTask?.EndDate ? moment(selectedTask?.EndDate).format('MMM DD') : moment(selectedTask?.ExpectedEndDate).format('MMM DD')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="wt-14-2">
                                                            <div className="cr-hed">
                                                                <h6>Project name</h6>
                                                                <p>{( selectedTask?.WMS_Projects?.Title) || '--'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="wt-14-2">
                                                            <div className="cr-hed">
                                                                <h6>Duration</h6>
                                                                <p>{getDuration(selectedTask || {})}</p>
                                                            </div>
                                                        </div >
                                                        {values.Status === TaskStatus.getValueByName('Completed') && (<div className="col-md-3 wt-14-2">
                                                            <div className="cr-hed ">
                                                                <h6>Completed date</h6>
                                                                <p>{selectedTask.CompletedAt ? moment(selectedTask.CompletedAt).format('MMM DD') : moment().format('MMM DD')}</p>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex tsk-icons">
                                                        {/* <a className="addtask-id active">
                                                        <span
                                                            className="iconify"
                                                            data-icon="streamline:interface-alert-warning-circle-warning-alert-frame-exclamation-caution-circle"
                                                        />
                                                    </a> */}
                                                        {/* <a onClick={() => setShowAttachments(!showAttachments)} id="attachment-id">
                                                        <span
                                                            className="iconify"
                                                            data-icon="material-symbols:attach-file"
                                                        />
                                                    </a> */}
                                                        {/* <a
                                                        className="dropdown-toggle"
                                                        href="#"
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"

                                                    >
                                                        <span
                                                            className="iconify"
                                                            data-icon="carbon:overflow-menu-vertical"
                                                        />
                                                    </a> */}
                                                        {/* <div className="dropdown-menu">
                                                        <a className="dropdown-item" href="#" />
                                                        <a className="dropdown-item" href="#" />
                                                        <a className="dropdown-item" href="#" />
                                                        <a className="dropdown-item" href="#" />
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {!showAttachments ?
                                            <div className="row addattachmentdata mtop-30 cont-data-at">
                                                <div className="dep-attachment">
                                                    <div className="col-md-12">
                                                        <div className="ad-attach-data">
                                                            <span className="iconify" data-icon="mdi:paperclip" />
                                                            <h5>No attachments added !</h5>
                                                            <div className="mtop-10">
                                                                <a href="#" id="attachmentid">
                                                                    {" "}
                                                                    <button type="button" className="ct-btn">
                                                                        <span
                                                                            className="iconify"
                                                                            data-icon="mdi:paperclip-plus"
                                                                            data-width="20px"
                                                                        />
                                                                        Add Attachment
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="taskcontent">
                                                    <div className="create-project create-task">
                                                        <div className="row ad-task mtop-30">
                                                            <div id="main-task-content" className="col-md-7 dep-left">
                                                                <div className="row">
                                                                    <div className="col-md-12 ">
                                                                        <div className=" inputformContainer mr-bt-35">
                                                                            <TextInput
                                                                                maxLength={100}
                                                                                required={true}
                                                                                type="text"
                                                                                wrapperClassName='mr-bt-35'
                                                                                value={values?.Title}
                                                                                error={errors && errors.Title}
                                                                                className="inputformdata form-control"
                                                                                name="projectname"
                                                                                id="taskname"
                                                                                placeholder="Task Name"
                                                                                label="Task name"
                                                                                onChange={(e) => handleChange("Title", e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 ">
                                                                        <div className="inputformContainer inputtextarea ">
                                                                            <textarea
                                                                                row={5}
                                                                                cols={20}
                                                                                className=" form-control  ad-textarea"
                                                                                id="depdescription"
                                                                                placeholder=" "
                                                                                required=""
                                                                                name="depdescription"
                                                                                value={values?.Description || ''}
                                                                                error={errors && errors.Title}
                                                                                onChange={(e) => handleChange("Description", e.target.value)} />


                                                                            <label htmlFor="depdescription" className="label ">
                                                                                Description
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5 ">
                                                                        <div
                                                                            className={`assignee-select mr-bt-35 ${errors && errors.Assignee ? "redborder" : ""}`}
                                                                        >
                                                                            <Select
                                                                                className="react-select-input"
                                                                                value={users && users.length && users.filter(u => u.value == values.Assignee)}
                                                                                inputId={"assignee"}
                                                                                error={errors && errors.Assignee}
                                                                                onChange={(selectedOption) => {
                                                                                    if (selectedOption) { handleChange("Assignee", selectedOption) }
                                                                                    else {
                                                                                        handleChange("Assignee", null);
                                                                                    }
                                                                                }}
                                                                                options={users}
                                                                                components={{ DropdownIndicator }}
                                                                                isSearchable={true}
                                                                                isClearable={true}
                                                                                noOptionsMessage={() => "No results found"}
                                                                            />
                                                                            <label htmlFor="assignee" className="label ">Assignee<span className="rd"> *</span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="select-crt-pt sl-plc-holder">
                                                                            <select
                                                                                className="form-select crt-select"
                                                                                aria-label="Filter select"
                                                                                required=""
                                                                                id="addtask-status"
                                                                                value={values?.Status}
                                                                                onChange={(e) => handleChange("Status", Number(e.target.value))}
                                                                            >
                                                                                {options?.map((option, index) => (
                                                                                    <option key={index} value={option?.ID}>
                                                                                        {option?.Name}
                                                                                    </option>
                                                                                ))}

                                                                            </select>
                                                                            <label htmlFor="addtask-status" className="label">Status <span className="rd"> *</span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 ">
                                                                        <div className="select-crt-p sl-plc-holder">
                                                                            <select
                                                                                className="form-select crt-select"
                                                                                aria-label="Filter select"
                                                                                required=""
                                                                                id="business-process"
                                                                                value={parseInt(values.Priority)}
                                                                                onChange={(e) => handleChange("Priority", parseInt(e.target.value))}
                                                                            >
                                                                                {priority?.map((option) => (
                                                                                    <option key={option.value} value={option.value}>
                                                                                        {option?.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            <label htmlFor="business-process" className="label">Priority <span className="rd"> *</span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className=" inputformContainer mr-bt-35">
                                                                            <DatePickerComponent
                                                                                required={true}
                                                                                name="StartDate"
                                                                                selected={values?.StartDate ? new Date(values.StartDate) : null}
                                                                                value={values?.StartDate ? moment(values?.StartDate).format("MM/DD/YYYY") : null}
                                                                                wrapperClassName={`mr-bt-35  ${errors && errors.ExpectedStartDate ? "redborder" : ""}`}
                                                                                onChange={(date) => handleChange("StartDate", date)}
                                                                                className={`${values?.Status === getProjectStatus.Completed && "disabled-textbox"} date-picker-styles`}
                                                                                placeholder='MM-DD-YY'
                                                                                label="Start Date"
                                                                                iconClass={'calendar-endDate'}
                                                                                futureDateOnly={true}
                                                                                disableCalendar={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5 ">
                                                                        <div className=" inputformContainer mr-bt-35">
                                                                            <DatePickerComponent
                                                                                required={true}
                                                                                name="DueDate"
                                                                                selected={values?.EndDate ? new Date(values.EndDate) : null}
                                                                                value={values?.EndDate ? moment(values?.EndDate).format("MM/DD/YYYY") : null}
                                                                                wrapperClassName={`mr-bt-35  ${errors && errors.ExpectedEndDate ? "redborder" : ""}`}
                                                                                onChange={(date) => handleChange("EndDate", date)}
                                                                                className={`${values?.Status === getProjectStatus.Completed && "disabled-textbox"} date-picker-styles`}
                                                                                placeholder='MM-DD-YY'
                                                                                label="Due Date"
                                                                                iconClass={'calendar-endDate'}
                                                                                futureDateOnly={true}
                                                                                disableCalendar={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-10 ">
                                                                        <div className="inputformContainer inputtextarea ">
                                                                            <textarea
                                                                                rows={10}
                                                                                cols={20}
                                                                                className=" form-control  ad-textarea"
                                                                                id="depcomment"
                                                                                placeholder=" "
                                                                                required=""
                                                                                name="depcomment"

                                                                            />
                                                                            <label htmlFor="depcomment" className="label ">
                                                                                Comments
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-md-5"
                                                                style={{ borderLeft: "1px solid #a3a3a330" }}
                                                            >
                                                                <div className="add-dependent">
                                                                    <div className="d-flex">
                                                                        <h5>Add dependent task</h5>
                                                                        <div className="dropdown d-inline-block prj-edit dep-info">
                                                                            <button
                                                                                type="button"
                                                                                className="notify-data"
                                                                                id="page-header-notifications-dropdown"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                            >
                                                                                <Icon className="iconify" icon={"streamline:interface-alert-warning-circle-warning-alert-frame-exclamation-caution-circle"} />
                                                                            </button>
                                                                            <div
                                                                                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                                                                aria-labelledby="page-header-notifications-dropdown"
                                                                                id="dependency-id"
                                                                                onMouseEnter={() => document.getElementById("dependency-id").classList.add("show")}
                                                                                onMouseLeave={() => document.getElementById("dependency-id").classList.remove("show")}
                                                                            >
                                                                                <div className="dep-content">
                                                                                    <h3>Dependency types</h3>
                                                                                    <p>
                                                                                        Following values define the type of relationship
                                                                                        to set between two tasks.
                                                                                    </p>
                                                                                    <h6 className="line-after pr-info">
                                                                                        <span>Finish-Start</span>
                                                                                    </h6>
                                                                                    <p>
                                                                                        The predecessor task requires finishing before
                                                                                        the successor task can start. This dependency is
                                                                                        the most common dependency type.
                                                                                    </p>
                                                                                    <h6 className="line-after pr-info">
                                                                                        <span>Start-Start</span>
                                                                                    </h6>
                                                                                    <p>
                                                                                        The predecessor task requires starting before the successor task can start.
                                                                                    </p>
                                                                                    <h6 className="line-after pr-info">
                                                                                        <span>Start-Finish</span>
                                                                                    </h6>
                                                                                    <p>
                                                                                        The predecessor task requires starting before the successor task can finish.
                                                                                    </p>
                                                                                    <h6 className="line-after pr-info">
                                                                                        <span>Finish-Finish</span>
                                                                                    </h6>
                                                                                    <p>
                                                                                        The predecessor task requires finishing before
                                                                                        the successor task can finish
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-5 ">
                                                                            <div className=" inputformContainer mr-bt-35">
                                                                                <input
                                                                                    type="text"
                                                                                    className="inputformdata form-control"
                                                                                    id="dependency-type"
                                                                                    placeholder=" "
                                                                                    required=""
                                                                                    name="projectname"
                                                                                    disabled={true}
                                                                                />
                                                                                <label htmlFor="assignee" className="label ">
                                                                                    Dependency Type<span className="red">*</span>
                                                                                </label>
                                                                                {/* <div className="flex-shrink-0 text-end">
                                                                                            <div className="dropdown dep-select">
                                                                                                <a
                                                                                                    className="dropdown-toggle"
                                                                                                    role="button"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-haspopup="true"
                                                                                                    aria-expanded="false"
                                                                                                >
                                                                                                    <span
                                                                                                        className="iconify"
                                                                                                        data-icon="ep:arrow-down"
                                                                                                    />
                                                                                                </a>
                                                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                                                    <a className="dropdown-item" href="#">
                                                                                                        Finish-Start
                                                                                                    </a>
                                                                                                    <a className="dropdown-item" href="#">
                                                                                                        Start-Start
                                                                                                    </a>
                                                                                                    <a className="dropdown-item" href="#">
                                                                                                        Start-Start
                                                                                                    </a>
                                                                                                    <a className="dropdown-item" href="#">
                                                                                                        Finish-Finish
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-7 ">
                                                                            <div className=" inputformContainer mr-bt-35">
                                                                                <input
                                                                                    type="text"
                                                                                    className="inputformdata form-control "
                                                                                    id="taskname"
                                                                                    placeholder=" "
                                                                                    required=""
                                                                                    name="projectname"
                                                                                    disabled={true}
                                                                                />
                                                                                <label htmlFor="taskname" className="label ">
                                                                                    Search task<span className="red">*</span>
                                                                                </label>
                                                                                <span
                                                                                    style={{ cursor: "auto" }}
                                                                                    className="iconify date-icon"
                                                                                    data-icon="material-symbols:search"
                                                                                    data-width={20}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            {Array.isArray(values?.WMS_ProjectDependentTasks) && values.WMS_ProjectDependentTasks.length > 0 && (
                                                                                <div className="task-dep-list">
                                                                                    <div className="d-flex">
                                                                                        <label className="tsk-id">Task ID</label>
                                                                                        <div className="track-btn">
                                                                                            <div className="ble-btn">
                                                                                                <Icon className="iconify" icon={"octicon:dot-fill-16"} />
                                                                                                {values?.WMS_ProjectDependentTasks.map((dependentTask) => (
                                                                                                    <span key={dependentTask.ID}>{dependentTask?.ProcessTaskNum}</span>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <select
                                                                                            className="form-select"
                                                                                            aria-label="Filter select"
                                                                                            required=""
                                                                                            id="addtask-status"
                                                                                        >
                                                                                            <option>Finish-Start</option>
                                                                                            <option>Start-Finish</option>
                                                                                            <option>Start-Start</option>
                                                                                            <option>Finish-Finish</option>
                                                                                        </select>
                                                                                        <div className="dep-name">
                                                                                            {values?.WMS_ProjectDependentTasks.map((dependentTask) => (
                                                                                                <span key={dependentTask.ID}>{dependentTask?.Title}</span>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="depdel-icon">
                                                                                        <button>
                                                                                            <Icon className="iconify" icon={"mdi:delete-outline"} />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {(isEdited) && (
                <div className="modal fade" id="closeconfirmation-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content confirm-modal-content">
                            <div className="modal-body">
                                <div className="confirm-message text-center">
                                    <div className="header">
                                        <h3>Confirm</h3>
                                        <p>You have modified this task. You can save your changes or cancel to discard your changes</p>
                                        <button onClick={handleModalClose} type="button" className="btn-cr-grey" ><span className="iconify"
                                            data-icon="ion:close-circle-outline"></span>Cancel</button>
                                        <button onClick={() => { handleModalSave() }} className="i-href-task-blue" id="savetask"><span className="iconify" data-icon="carbon:save" data-width="20px"></span> Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

const mapStateToProps = state => ({
    project: state && state.projects.project,
});
const mapDispatchToProps = { editTask, getTaskByProjectId, getAllUsers, getAllStatus, getAllTaskDashBoard, getProjectById };

export default connect(mapStateToProps, mapDispatchToProps)(Modal); 
