import React from 'react';
import { connect } from 'react-redux';
import { getProjectList } from "../../store/projects/action";
import Onboarding from './Onboarding';

const ProjectList = () => {
    return ( 
            <div className="container-fluid">
                <div className="overview-header">
                    <div className="row bt-btm">
                        <div className="col-md-12">
                            <div className="prj-header">
                                <h4>Projects</h4>
                                <ul className="nav nav-pills br-btm main-nav navtab-pt tab-full" id="patients-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link  pad-3 active" id="pt-onboarding-tab" data-bs-toggle="pill" data-bs-target="#pt-onboarding" type="button" role="tab" aria-controls="pt-onboarding" aria-selected="true">Facility Onboarding</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link pad-3 disabled" id="pt-task-tab" data-bs-toggle="pill" data-bs-target="#pt-task" type="button" role="tab" aria-controls="pt-task" aria-selected="false">Facility Renewal</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link pad-3 disabled" id="pt-calendar-tab" data-bs-toggle="pill" data-bs-target="#pt-calendar" type="button" role="tab" aria-controls="pt-calendar" aria-selected="false">Facility Auditing</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pt-onboarding" role="tabpanel" aria-labelledby="pt-onboarding-tab"
                        tabIndex="0">
                        <Onboarding />
                    </div>
                    <div className="tab-pane fade" id="pt-task" role="tabpanel" aria-labelledby="#pt-task"
                        tabIndex="0">
                        <p>test 2</p>
                    </div>
                    <div className="tab-pane fade" id="pt-calendar" role="tabpanel" aria-labelledby="pt-calendar-tab"
                        tabIndex="0">
                    </div>
                </div>
            </div> 
    );
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = { getProjectList };

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList); 